import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {ERROR_CODES} from "../../types/constants/error-codes";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {authActions} from "../../redux/slices/auth-slice";
import {projectsActions} from "../../redux/slices/projects-slice";
import {Project} from "../../types/project/Project";
import {AlertData} from "../../types/components/AlertData";

const useProjectList = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData } = useFetch();

  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<number>(null);
  const [selectedProject, setSelectedProject] = useState<Project>(null);

  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);


  const initShowNewModal = (show: boolean) => {
    setShowNewModal(show);
  }

  const initShowEditModal = (show: boolean) => {
    setShowEditModal(show);
  }

  const initShowDeleteModal = (show: boolean) => {
    setShowDeleteModal(show);
  }

  const loadProjects = async () => {
    const resp = await fetchData('/project/list', null, true);
    if (!resp) {
      return;
    }
    if (resp.errorCode && resp.errorCode === ERROR_CODES.LOGIN_INVALID_AUTH_TOKEN) {
      dispatch(authActions.logout());
      navigate('/login?eventType=logged_out');
      return;
    }
    if (resp.projects) {
      setProjects(resp.projects);
      dispatch(projectsActions.initProjects(resp.projects));
    }
  }

  const initEditProject = (project: Project) => {
    setSelectedProjectId(project.id);
  }

  const initDeleteProject = (project: Project) => {
    setSelectedProject(project);
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    projects: projects,
    selectedProjectId: selectedProjectId,
    selectedProject: selectedProject,
    showNewModal: showNewModal,
    showEditModal: showEditModal,
    showDeleteModal: showDeleteModal,
    initShowNewModal: initShowNewModal,
    initShowEditModal: initShowEditModal,
    initShowDeleteModal: initShowDeleteModal,
    loadProjects: loadProjects,
    initEditProject: initEditProject,
    initDeleteProject: initDeleteProject,
  };

}

export default useProjectList;