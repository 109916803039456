import {Outlet, useNavigate} from "react-router-dom";
import {useContext, useEffect} from "react";
import {AuthState} from "../../types/state/AuthState";
import {useSelector} from "react-redux";

const AuthLayout = () => {

  const { isLoggedIn }: AuthState = useSelector((state: any) => state.auth);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, []);

  return (
      <>
        <Outlet />
      </>
  );
};

export default AuthLayout;
