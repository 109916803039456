import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserData} from "../../types/user/UserData";
import {AuthState} from "../../types/state/AuthState";

const initialState: AuthState = {
  isLoggedIn: false,
  userData: null,
  language: 'DE',
}

const login = (authState: AuthState, action: PayloadAction<UserData>) => {
  authState.isLoggedIn = true;
  authState.userData = action.payload;
}

const logout = (authState: AuthState) => {
  authState.isLoggedIn = false;
  authState.userData = null;
}

const initLanguage = (authState: AuthState, action: PayloadAction<string>) => {
  authState.language = action.payload;
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    login: login,
    logout: logout,
    initLanguage: initLanguage,
  },
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;