import {AuthState} from "../../types/state/AuthState";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {BreacrumbData} from "../../types/components/BreacrumbData";
import useEventList from "../../hooks/event/event-list";
import Alert from "../../components/_general/Alert";
import DefaultErrorAlert from "../../components/_general/DefaultErrorAlert";
import Breadcrumb from "../../components/_general/Breadcrumb";
import {PageState} from "../../types/state/PageState";
import NewEventFragment from "../../page-fragments/event/NewEventFragment";
import EditEventFragment from "../../page-fragments/event/EditEventFragment";
import DeleteEventFragment from "../../page-fragments/event/DeleteEventFragment";
import Button from "../../components/_general/Button";
import EventsCalendarTable from "../../components/event/event-list/EventsCalendarTable";
import {pageActions} from "../../redux/slices/page-slice";
import useLabels from "../../hooks/use-labels";
import labels from "../../types/constants/labels";
import EventProjectsNav from "../../components/event/event-project/EventProjectsNav";
import {useParams, useSearchParams} from "react-router-dom";
import {Project} from "../../types/project/Project";
import {ProjectsState} from "../../types/state/ProjectsState";
import EventFilter from "../../components/event/event-filter/EventFilter";
import {hasFeature} from "../../utils/app-utils";
import FEATURES from "../../types/constants/features";

const EventCalendarPage = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  const { projectId: projectIdParam} = useParams();

  const { isLoggedIn, userData }: AuthState = useSelector((state: any) => state.auth);
  const { alertData: globalAlertData }: PageState = useSelector((state: any) => state.page);

  const { projects: allProjects }: ProjectsState = useSelector((state: any) => state.projects);
  const projects = allProjects && allProjects.filter((project: Project) => project.projectTypeId === 3);

  const { isLoading, hasError, alertData,
    startDate, endDate, projectId,
    events, calendarDays,
    eventStart, selectedEventId, selectedEvent,
    showNewModal, showEditModal, showDeleteModal,
    initDates, initStartDate, initEndDate,
    initShowNewModal, initShowEditModal, initShowDeleteModal,
    initProjectId, loadEvents, initCalendarDays,
    initNewEventWithStart, initEditEvent, initDeleteEvent } = useEventList();

  const breadcrumbPages: BreacrumbData[] = [{ item: label(labels.events.titles.EVENTS), to: '/events'}];

  useEffect(() => {
    initDates();
  }, []);

  useEffect(() => {
    if (projects && projects.length > 0) {
      initProjectId(projects, projectIdParam);
    }
  }, [projects, projectIdParam]);

  useEffect(() => {
    (async () => {
      if (projectId && startDate && endDate) {
        await loadEvents();
      }
    })();
  }, [projectId, startDate, endDate]);

  useEffect(() => {
    (async () => {
      if (events) {
        await initCalendarDays();
      }
    })();
  }, [events]);


  useEffect(() => {
    let timer = setTimeout(() => {
        dispatch(pageActions.clear());
    }, 5 * 1000);
    return () => {
        clearTimeout(timer);
    };
  }, [globalAlertData]);

  const handleNewClick = () => {
    initShowNewModal(true);
  }

  if (!isLoggedIn || !hasFeature(FEATURES.EVENTS, userData)) {
    return;
  }

  return (
      <>
        {hasError &&
          <DefaultErrorAlert />
        }
        {globalAlertData &&
            <Alert alertData={globalAlertData} />
        }
        {alertData &&
            <Alert alertData={alertData} />
        }

        {projects && projectId &&
          <EventProjectsNav projects={projects}
                            projectId={projectId} />
        }

        <Breadcrumb pages={breadcrumbPages} />

        <div className="d-flex justify-content-between mb-3">
          <h3>{label(labels.events.titles.EVENTS)}</h3>
          <Button classes="btn-primary" icon="plus" label={label(labels.actions.ADD)}
                  onClick={handleNewClick}/>
        </div>

        {startDate && endDate &&
          <EventFilter startDate={startDate}
                       endDate={endDate}
                       initStartDate={initStartDate}
                       initEndDate={initEndDate} />
        }

        {calendarDays &&
          <EventsCalendarTable calendarDays={calendarDays}
                               isLoading={isLoading}
                               onInitShowNewModal={initShowNewModal}
                               onInitShowEditModal={initShowEditModal}
                               onInitShowDeleteModal={initShowDeleteModal}
                               onInitNewEventWithStart={initNewEventWithStart}
                               onInitEditEvent={initEditEvent}
                               onInitDeleteEvent={initDeleteEvent} />
        }

        <NewEventFragment
                    eventStart={eventStart}
                    projectId={projectId}
                    showNewModal={showNewModal}
                    onInitShowNewModal={initShowNewModal}
                    onReloadEvents={loadEvents} />

        <EditEventFragment eventId={selectedEventId}
                     showEditModal={showEditModal}
                     onInitShowEditModal={initShowEditModal}
                     onReloadEvents={loadEvents} />

        <DeleteEventFragment event={selectedEvent}
                       showDeleteModal={showDeleteModal}
                       onInitShowDeleteModal={initShowDeleteModal}
                       onReloadEvents={loadEvents} />


      </>
  );
}

export default EventCalendarPage;