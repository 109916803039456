import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ProjectForm from "../../components/project/project-edit/ProjectForm";
import {ProjectsState} from "../../types/state/ProjectsState";
import useEditProject from "../../hooks/project/edit-project";
import {Project} from "../../types/project/Project";

interface Props {
  projectId: number;
  showEditModal: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onReloadProjects: () => void;
}

const EditProjectFragment = ({ projectId, showEditModal, onInitShowEditModal, onReloadProjects }: Props) => {

  const { projectTypes }: ProjectsState = useSelector((state: any) => state.projects);

  const { isLoading, hasError,
    project, alertData,
    loadProject,
    saveEditProject, cancelEditProject } = useEditProject();

  useEffect(() => {
    (async () => {
      if (projectId) {
        await loadProject(`${projectId}`);
      }
    })();
  }, [projectId]);

  const handleSaveProject = async (project: Project) => {
    await saveEditProject(project);
    await onReloadProjects();
  }

  const handleCancel = () => {
    cancelEditProject();
  }

  return (
      <>
        <ProjectForm project={project}
                     projectTypes={projectTypes}
                     alertData={alertData}
                     mode="EDIT"
                     modalId="editProjectForm"
                     showModal={showEditModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowEditModal}
                     onSaveProject={handleSaveProject}
                     onCancel={handleCancel} />
      </>
  );

}

export default EditProjectFragment;