import {configureStore} from '@reduxjs/toolkit';
import {authReducer} from "./slices/auth-slice";
import {pageReducer} from "./slices/page-slice";
import {loadState, saveState} from "../utils/storage-utils";
import {projectsReducer} from "./slices/projects-slice";
import {eventsReducer} from "./slices/events-slice";

const persistedState: any = loadState();

const storeOptions: any = {
  reducer: {
    auth: authReducer,
    page: pageReducer,
    projects: projectsReducer,
    events: eventsReducer,
  },
};

if (persistedState) {
  storeOptions.preloadedState = persistedState;
}

const reduxStore = configureStore(storeOptions);

reduxStore.subscribe(() => {
  saveState(reduxStore.getState());
});

export default reduxStore;