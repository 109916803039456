import {addDays, differenceInDays, format, parse} from "date-fns";
import DATE_FORMATS from "../types/constants/date-formats";
import {Event} from "../types/event/Event";
import {CalendarDay} from "../types/calendar/CalendarDay";
import {EventType} from "../types/event/EventType";
import {Color} from "../types/_general/Color";
import {Project} from "../types/project/Project";

export const createNewEvent = (colors: Color[], eventTypes: EventType[], projectId: number, eventStart?: Date): Event => {
  const event: Event = {
    id: null,
    eventName: null,
    eventStart: null,
    eventStartAsDate: eventStart ? eventStart : null,
    eventEnd: null,
    color: colors && colors.length > 0 ? colors[0].code : null,
    eventTypeId: eventTypes && eventTypes.length > 0 ? eventTypes[0].id : null,
    projectId: projectId,
  };
  return event;
}

export const parseEventValues = (event: Event) => {
  if (!event) {
    return;
  }

  // if (event.eventDate) {
  //   const eventDate: Date = new Date(event.eventDate);
  //   const eventDateFormatted: string = format(eventDate, DATE_FORMATS.DATE);
  //   event.eventDateAsDate = eventDate;
  //   event.eventDateFormatted = eventDateFormatted;
  //
  // } else if (event.eventDateAsDate) {
  //   const eventDateFormatted: string = format(event.eventDateAsDate, DATE_FORMATS.DATE);
  //   event.eventDateFormatted = eventDateFormatted;
  // }

  if (event.eventStart) {
    const eventStart: Date = new Date(event.eventStart);
    const eventStartFormatted: string = format(eventStart, DATE_FORMATS.DATE);
    event.eventStartAsDate = eventStart;
    event.eventStartFormatted = eventStartFormatted;

  } else if (event.eventStartAsDate) {
    const eventStartFormatted: string = format(event.eventStartAsDate, DATE_FORMATS.DATE);
    event.eventStartFormatted = eventStartFormatted;

  } else {
    event.eventStartAsDate = null;
    event.eventStartFormatted = null;
  }

  if (event.eventEnd) {
    const eventEnd: Date = new Date(event.eventEnd);
    const eventEndFormatted: string = format(eventEnd, DATE_FORMATS.DATE);
    event.eventEndAsDate = eventEnd;
    event.eventEndFormatted = eventEndFormatted;

  } else if (event.eventEndAsDate) {
    const eventEndFormatted: string = format(event.eventEndAsDate, DATE_FORMATS.DATE);
    event.eventEndFormatted = eventEndFormatted;
  } else {
    event.eventEndAsDate = null;
    event.eventEndFormatted = null;
  }

};

export const formatEventValues = (event: Event) => {
  if (!event) {
    return;
  }

  if (event.eventDateFormatted) {
    const eventDateAsDate: Date = parse(event.eventDateFormatted, DATE_FORMATS.DATE, new Date());
    event.eventDateAsDate = eventDateAsDate;
    event.eventDate = format(event.eventDateAsDate, DATE_FORMATS.FULL_ISO);
    console.log(event.eventDate);
  }

  if (event.eventStartFormatted) {
    const eventStartAsDate: Date = parse(event.eventStartFormatted, DATE_FORMATS.DATE, new Date());
    event.eventStartAsDate = eventStartAsDate;
    event.eventStart = format(event.eventStartAsDate, DATE_FORMATS.FULL_ISO);
    console.log(event.eventStart);
  }

  if (event.eventEndFormatted) {
    const eventEndAsDate: Date = parse(event.eventEndFormatted, DATE_FORMATS.DATE, new Date());
    event.eventEndAsDate = eventEndAsDate;
    event.eventEnd = format(event.eventEndAsDate, DATE_FORMATS.FULL_ISO);
    console.log(event.eventEnd);
  }
}

export const convertToEvent = (formEvent: Event): Event => {

  const {
    id,
    eventName,
    eventDate,
    eventStart,
    eventEnd,
    color,
    eventTypeId,
    projectId,
  } = formEvent;

  const event: Event = {
    id: id ? Number(id) : null,
    eventName: eventName || null,
    eventStart: eventStart || null,
    eventEnd: eventEnd || null,
    color: color || null,
    eventTypeId: Number(eventTypeId),
    projectId: Number(projectId),
  }
  return event;
}

export const getEventsByDateMap = (events: Event[]): Map<string, Event[]> => {
  const eventsByDateMap: Map<string, Event[]> = new Map();

  events.forEach((event: Event) => {
    const startDate: Date = event.eventStartAsDate;
    const endDate: Date = event.eventEndAsDate;
    const daysBetween: number = differenceInDays(endDate, startDate);
    let date: Date = startDate;
    for (let i = 0; i < daysBetween + 1; i++) {
      let dateFormatted = format(date, DATE_FORMATS.DATE);
      let eventsByDate: Event[] = []
      if (eventsByDateMap.has(dateFormatted)) {
        eventsByDate = eventsByDateMap.get(dateFormatted);
      }
      eventsByDate.push(event);
      eventsByDateMap.set(dateFormatted, eventsByDate);
      date = addDays(date, 1);
    }
  });

  return eventsByDateMap;
}

export const getCalendarDays = (eventsByDateMap: Map<string, Event[]>, startDate: Date, endDate: Date): CalendarDay[] => {
  const calendarDays: CalendarDay[] = [];
  const daysBetween: number = differenceInDays(endDate, startDate);
  let date: Date = startDate;
  for (let i = 0; i < daysBetween + 1; i++) {
    const dateFormatted: string = format(date, DATE_FORMATS.DATE)
    const events: Event[] = eventsByDateMap.get(dateFormatted);
    const calendarDay: CalendarDay = {
      date: date,
      events: events ? events : [],
    }
    calendarDays.push(calendarDay);
    date = addDays(date, 1);
  }
  return calendarDays;
}