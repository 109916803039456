import {UserData} from "../types/user/UserData";

export const addAuthData = (headers: any, userData: UserData) => {
  if (userData) {
    const { username, authToken } = userData;
    headers['Auth-Username'] = username;
    headers['Auth-Token'] = authToken;
  }
};

export const createPostRequest = (body: any) => {
  const reqData = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body,
  }
  return reqData;
}