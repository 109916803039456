import {AuthState} from "../../types/state/AuthState";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {BreacrumbData} from "../../types/components/BreacrumbData";
import useProjectList from "../../hooks/project/project-list";
import Alert from "../../components/_general/Alert";
import DefaultErrorAlert from "../../components/_general/DefaultErrorAlert";
import Breadcrumb from "../../components/_general/Breadcrumb";
import {PageState} from "../../types/state/PageState";
import NewProjectFragment from "../../page-fragments/project/NewProjectFragment";
import EditProjectFragment from "../../page-fragments/project/EditProjectFragment";
import DeleteProjectFragment from "../../page-fragments/project/DeleteProjectFragment";
import Button from "../../components/_general/Button";
import ProjectsTable from "../../components/project/project-list/ProjectsTable";
import {useParams} from "react-router-dom";
import {pageActions} from "../../redux/slices/page-slice";
import useLabels from "../../hooks/use-labels";
import labels from "../../types/constants/labels";
import {hasFeature} from "../../utils/app-utils";
import FEATURES from "../../types/constants/features";

const ProjectListPage = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  const { isLoggedIn, userData }: AuthState = useSelector((state: any) => state.auth);
  const { alertData }: PageState = useSelector((state: any) => state.page);

  const { isLoading, hasError,
    projects, selectedProjectId, selectedProject,
    showNewModal, showEditModal, showDeleteModal,
    initShowNewModal, initShowEditModal, initShowDeleteModal,
    loadProjects, initEditProject, initDeleteProject } = useProjectList();

  const breadcrumbPages: BreacrumbData[] = [{ item: label(labels.projects.titles.PROJECTS), to: '/projects'}];

  useEffect(() => {
    (async () => {
      await loadProjects();
    })();
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
        dispatch(pageActions.clear());
    }, 5 * 1000);
    return () => {
        clearTimeout(timer);
    };
  }, [alertData]);

  const handleNewClick = () => {
    initShowNewModal(true);
  }

  if (!isLoggedIn || !hasFeature(FEATURES.PROJECTS, userData)) {
    return;
  }

  return (
      <>
        {hasError &&
          <DefaultErrorAlert />
        }
        {alertData &&
          <Alert alertData={alertData} />
        }

        <Breadcrumb pages={breadcrumbPages} />

        <div className="d-flex justify-content-between mb-3">
          <h3>{label(labels.projects.titles.PROJECTS)}</h3>
          <Button classes="btn-primary" icon="plus" label={label(labels.actions.ADD)}
                  onClick={handleNewClick}/>
        </div>

        {projects &&
          <ProjectsTable projects={projects}
                         isLoading={isLoading}
                         onInitShowEditModal={initShowEditModal}
                         onInitShowDeleteModal={initShowDeleteModal}
                         onInitEditProject={initEditProject}
                         onInitDeleteProject={initDeleteProject} />
        }

        <NewProjectFragment showNewModal={showNewModal}
                    onInitShowNewModal={initShowNewModal}
                    onReloadProjects={loadProjects} />

        <EditProjectFragment projectId={selectedProjectId}
                     showEditModal={showEditModal}
                     onInitShowEditModal={initShowEditModal}
                     onReloadProjects={loadProjects} />

        <DeleteProjectFragment project={selectedProject}
                       showDeleteModal={showDeleteModal}
                       onInitShowDeleteModal={initShowDeleteModal}
                       onReloadProjects={loadProjects} />


      </>
  );
}

export default ProjectListPage;