import { format } from "date-fns";
import {Event} from "../../../types/event/Event";
import DATE_FORMATS from "../../../types/constants/date-formats";
import {CalendarDay} from "../../../types/calendar/CalendarDay";
import de from "date-fns/locale/de";
import Holidays from "date-holidays";
import React, {useState} from "react";
import EventItem from "./EventItem";
import Icon from "../../_general/Icon";
import EventsCalendarHeading from "./EventsCalendarHeading";
import Button from "../../_general/Button";
import useLabels from "../../../hooks/use-labels";
import labels from "../../../types/constants/labels";

interface Props {
  calendarDay: CalendarDay;
  index: number;
  holidays: Holidays;
  onInitShowNewModal: (show: boolean) => void;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitNewEventWithStart: (eventStart: Date) => void;
  onInitEditEvent: (event: Event) => void;
  onInitDeleteEvent: (event: Event) => void;
}
const EventsCalendarRow = ({ calendarDay, index, holidays, onInitShowNewModal, onInitShowEditModal, onInitShowDeleteModal,
                             onInitNewEventWithStart, onInitEditEvent, onInitDeleteEvent }: Props) => {

  const { getLabel: label } = useLabels();

  const [showAddButton, setShowAddButton] = useState<boolean>(false);

  const weekDay = format(calendarDay.date, DATE_FORMATS.WEEKDAY, {locale: de});
  const dateFormatted = format(calendarDay.date, DATE_FORMATS.DATE_DE);

  const isWeekend = weekDay === 'Sa' || weekDay === 'So';
  const isHoliday = holidays.isHoliday(calendarDay.date) ? true : false;


  const handleMouseOver = () => {
    setShowAddButton(true);
  }

  const handleMouseOut = () => {
    setShowAddButton(false);
  }

  const handleNewClick = (eventStart: Date) => {
    onInitShowNewModal(true);
    onInitNewEventWithStart(new Date(eventStart.getTime()));
  }


  return (
      <>
        {(index === 0 || calendarDay.date.getDate() === 1) &&
            <EventsCalendarHeading
                date={calendarDay.date} />
        }
        <tr onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <td>
            <small>
              <Icon icon="calendar" classes="me-3" />
            </small>
          </td>
          <td>
            <div className={`d-inline-block ${isWeekend || isHoliday ? 'text-muted' : ''}`}>
              {`${weekDay}, ${dateFormatted}`}
            </div>
          </td>
          <td>
            {calendarDay.events &&
              calendarDay.events.map((event: Event) => (
                  <EventItem key={event.id} event={event} isWeekend={isWeekend} isHoliday={isHoliday}
                             onInitShowEditModal={onInitShowEditModal}
                             onInitShowDeleteModal={onInitShowDeleteModal}
                             onInitEditEvent={onInitEditEvent}
                             onInitDeleteEvent={onInitDeleteEvent} />
              ))
            }
            {showAddButton &&
                <Button classes="btn-light btn-sm btn-tiny" icon="plus" label={label(labels.actions.ADD)}
                onClick={() => { handleNewClick(calendarDay.date) }}/>
            }
          </td>
        </tr>
      </>
  );
}

export default EventsCalendarRow;