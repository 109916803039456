import labels from "./labels";

export const ERROR_CODES: any = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  LOGIN_INVALID_USERNAME_PASSWORD: 'LOGIN_INVALID_USERNAME_PASSWORD',
  LOGIN_INVALID_AUTH_TOKEN: 'LOGIN_INVALID_AUTH_TOKEN',
  LOGIN_USER_BLOCKED: 'LOGIN_USER_BLOCKED',
}

export const ERROR_CODE_LABELS: any = {
  UNKNOWN_ERROR: labels.errors.UNKNOWN_ERROR,
  LOGIN_INVALID_USERNAME_PASSWORD: labels.login.errors.INVALID_USERNAME_PASSWORD,
  LOGIN_USER_BLOCKED: labels.login.errors.INVALID_USERNAME_PASSWORD,
}
