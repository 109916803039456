import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import ModalFrame from "../../_general/ModalFrame";
import Alert from "../../_general/Alert";
import DefaultErrorAlert from "../../_general/DefaultErrorAlert";
import {AlertData} from "../../../types/components/AlertData";
import Button from "../../_general/Button";
import {TimeRecord} from "../../../types/time-record/TimeRecord";
import useLabels from "../../../hooks/use-labels";
import labels from "../../../types/constants/labels";
import {Project} from "../../../types/project/Project";
import {convertToTimeRecord, formatTimeRecordValues} from "../../../utils/time-record-utils";

interface Props {
  timeRecord: TimeRecord;
  projects: Project[];
  alertData: AlertData;
  mode: string;
  modalId: string;
  showModal: boolean;
  isLoading: boolean;
  hasError: boolean;
  onInitShowModal: (show: boolean) => void;
  onSaveTimeRecord: (timeRecord: TimeRecord) => void;
  onCancel: () => void;
}

const TimeRecordForm = ({ timeRecord, projects, alertData, mode, modalId, showModal,
                           isLoading, hasError, onInitShowModal, onSaveTimeRecord, onCancel }: Props) => {

  const { getLabel: label } = useLabels();

  const {
    control,
    register,
    handleSubmit: onSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm<TimeRecord>();

  useEffect(() => {
    reset(timeRecord);
  }, [timeRecord]);


  const handleSubmit = (formTimeRecord: TimeRecord) => {
    formatTimeRecordValues(formTimeRecord);

    const timeRecord: TimeRecord = convertToTimeRecord(formTimeRecord);

    onSaveTimeRecord(timeRecord);
    onInitShowModal(false);

  }

  const handleCancel = () => {
    onCancel();
    onInitShowModal(false);
  }

  return (
      <>
          <ModalFrame id={modalId} showModal={showModal}
                      title={mode === 'EDIT' ? label(labels.timeRecords.actions.EDIT_TIME_RECORD) :
                          label(labels.timeRecords.actions.NEW_TIME_RECORD)}
                      scrollable={true}
                      onCancel={handleCancel}>
            {isLoading &&
              <p>{label(labels.timeRecords.titles.TIME_RECORD_LOADED)}</p>
            }

            {mode === 'EDIT' && !timeRecord &&
              <p>{label(labels.timeRecords.titles.NO_TIME_RECORD_FOUND)}</p>
            }

            {alertData &&
                <Alert alertData={alertData} />
            }
            {hasError &&
                <DefaultErrorAlert />
            }

            {timeRecord &&
              <form onSubmit={onSubmit(handleSubmit)}>
                <div className="modal-body">

                  {mode === 'EDIT' &&
                    <input type="hidden" id={`${modalId}-id`}
                           {...register("id", {})}
                           defaultValue={timeRecord.id}
                           className="form-control" />
                  }

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-recordDateFormatted`} className="form-label">
                      {label(labels.timeRecords.fields.RECORD_DATE)}
                    </label>
                    <input type="date" id={`${modalId}-recordDateFormatted`}
                           {...register("recordDateFormatted", { required: true })}
                           className={`form-control ${errors.recordDateFormatted ? 'is-invalid' : ''}`}
                           placeholder={label(labels.timeRecords.fields.RECORD_DATE)}
                            required />
                    {errors.recordDateFormatted &&
                        <div className="invalid-feedback">
                          {label(labels.timeRecords.fields.RECORD_DATE_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="row">
                    <div className="col form-group mb-2 pe-0">
                      <label htmlFor={`${modalId}-recordFromFormatted`} className="form-label">
                        {label(labels.timeRecords.fields.RECORD_FROM)}
                      </label>
                      <input type="time" id={`${modalId}-recordFromFormatted`}
                             {...register("recordFromFormatted", { required: true, pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/ })}
                             className={`form-control ${errors.recordFromFormatted ? 'is-invalid' : ''}`}
                             placeholder={label(labels.timeRecords.fields.RECORD_FROM)} required />
                      {errors.recordFromFormatted &&
                          <div className="invalid-feedback">
                            {label(labels.timeRecords.fields.RECORD_FROM_INVALID)}
                          </div>
                      }
                    </div>

                    <div className="col form-group mb-2">
                      <label htmlFor={`${modalId}-recordToFormatted`} className="form-label">
                        {label(labels.timeRecords.fields.RECORD_TO)}
                      </label>
                      <input type="time" id={`${modalId}-recordToFormatted`}
                             {...register("recordToFormatted", { required: true, pattern: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/ })}
                             className={`form-control ${errors.recordToFormatted ? 'is-invalid' : ''}`}
                             placeholder={label(labels.timeRecords.fields.RECORD_TO)} required />
                      {errors.recordToFormatted &&
                          <div className="invalid-feedback">
                            {label(labels.timeRecords.fields.RECORD_TO_INVALID)}
                          </div>
                      }
                    </div>
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-effort`} className="form-label">
                      {label(labels.timeRecords.fields.EFFORT)}
                    </label>
                    <input type="text" id={`${modalId}-effort`}
                           {...register("effort", { required: true, maxLength: 255, pattern: /^(?! ).*(?<! )$/ })}
                           className={`form-control ${errors.effort ? 'is-invalid' : ''}`}
                           placeholder={label(labels.timeRecords.fields.EFFORT)} required />
                    {errors.effort &&
                        <div className="invalid-feedback">
                          {label(labels.timeRecords.fields.EFFORT_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-projectId`} className="form-label">
                      {label(labels.timeRecords.fields.PROJECT)}
                    </label>
                    <select id={`${modalId}-projectId`} className="form-select"
                            {...register("projectId")} >
                      {projects.map((project: Project) => (
                          <option key={project.id}
                                  value={project.id}>
                            {project.projectName}
                          </option>
                      ))}
                    </select>
                  </div>

                </div>
                <div className="modal-footer">
                  <Button classes="btn-default" icon="x" label={label(labels.actions.CANCEL)}
                          onClick={handleCancel} />
                  <Button type="submit" classes="btn-primary" icon="check" label={label(labels.actions.SAVE)} />
                </div>
              </form>
            }
          </ModalFrame>
      </>
  );
}

export default TimeRecordForm;