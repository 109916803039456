import {Project} from "../../../types/project/Project";
import Button from "../../_general/Button";
import classes from "./ProjectRow.module.scss";

interface Props {
  project: Project;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditProject: (project: Project) => void;
  onInitDeleteProject: (project: Project) => void;
}
const ProjectRow = ({ project, onInitShowEditModal, onInitShowDeleteModal, onInitEditProject, onInitDeleteProject }: Props) => {

  const handleEditClick = () => {
    onInitShowEditModal(true);
    onInitEditProject(project);
  }

  const handleDeleteClick = () => {
    onInitShowDeleteModal(true);
    onInitDeleteProject(project);
  }

  return (
      <>
        <tr>
          <th scope="row">{project.id}</th>
          <td>{project.projectName}</td>
          <td className={classes.middle}>
            {project.color &&
                <div className={classes.colorBadge} style={{backgroundColor: `#${project.color}`}} />
            }
          </td>
          <td><small>{project.projectType.projectTypeName}</small></td>
          <td className={`text-end ${classes.buttonCol}`}>
            <Button classes="btn-light btn-sm me-2" icon="pencil"
                    onClick={handleEditClick}
                // data-bs-toggle="modal" data-bs-target="#cardEditionFormModal"
            />
            <Button classes="btn-light btn-sm" icon="trash"
                    onClick={handleDeleteClick}
                // data-bs-toggle="modal" data-bs-target="#cardEditionFormModal"
            />
          </td>
        </tr>
      </>
  );
}

export default ProjectRow;