import ProjectRow from "./ProjectRow";
import Spinner from "../../_general/Spinner";
import {Project} from "../../../types/project/Project";
import React from "react";
import useLabels from "../../../hooks/use-labels";
import labels from "../../../types/constants/labels";

interface Props {
  projects: Project[];
  isLoading: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditProject: (project: Project) => void;
  onInitDeleteProject: (project: Project) => void;
}
const ProjectsTable = ({ projects, isLoading, onInitShowEditModal, onInitShowDeleteModal, onInitEditProject, onInitDeleteProject }: Props) => {

  const { getLabel: label } = useLabels();

  if (isLoading) {
    return (
        <>
          <Spinner />
          <p className="text-muted">{label(labels.projects.titles.PROJECTS_LOADED)}</p>
        </>
    );
  }
  if (!projects || projects.length === 0) {
    return (
        <p className="text-muted">{label(labels.projects.titles.NO_PROJECTS_FOUND)}</p>
    );
  }

  return (
      <>
        <table className="table table-striped mt-4">
          <thead>
            <tr>
              <th scope="col">{label(labels.projects.fields.ID)}</th>
              <th scope="col">{label(labels.projects.fields.NAME)}</th>
              <th scope="col">{label(labels.projects.fields.COLOR)}</th>
              <th scope="col">{label(labels.projects.fields.TYPE)}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project: Project) => (
              <ProjectRow
                key={project.id}
                project={project}
                onInitShowEditModal={onInitShowEditModal}
                onInitShowDeleteModal={onInitShowDeleteModal}
                onInitEditProject={onInitEditProject}
                onInitDeleteProject={onInitDeleteProject} />
              ))}
          </tbody>

        </table>
      </>
  );

}

export default ProjectsTable;