import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {createPostRequest} from "../../utils/api-utils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/app-utils";
import {Event} from "../../types/event/Event";
import {useDispatch} from "react-redux";
import {pageActions} from "../../redux/slices/page-slice";
import useLabels from "../use-labels";
import labels from "../../types/constants/labels";

const useDeleteEvent = () => {

  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const { isLoading, hasError, fetchData } = useFetch();

  const [alertData, setAlertData] = useState<AlertData>(null);

  const deleteEvent = async  (event: Event) => {
    const reqData = createPostRequest(null);
    const respData = await fetchData(`/event/delete/${event.id}`, reqData, true);
    if (!respData) {
      return;
    }

    const alert: AlertData = handleError(respData, label);
    if (alert) {
      setAlertData(alert);
      return;
    }

    const successAlert: AlertData = createSuccessAlert(label(labels.events.alerts.DELETED_SUCCESSFULLY, event.eventName));
    dispatch(pageActions.initAlertData(successAlert));
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    alertData: alertData,
    deleteEvent: deleteEvent,
  };

}

export default useDeleteEvent;