import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import EventForm from "../../components/event/event-edit/EventForm";
import useEditEvent from "../../hooks/event/edit-event";
import {Event} from "../../types/event/Event";
import {ProjectsState} from "../../types/state/ProjectsState";
import {EventsState} from "../../types/state/EventsState";
import {Project} from "../../types/project/Project";

interface Props {
  eventId: number;
  showEditModal: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onReloadEvents: () => void;
}

const EditEventFragment = ({ eventId, showEditModal, onInitShowEditModal, onReloadEvents }: Props) => {

  const { eventTypes }: EventsState = useSelector((state: any) => state.events);
  const { projects: allProjects }: ProjectsState = useSelector((state: any) => state.projects);

  const { isLoading, hasError,
    event, alertData,
    loadEvent,
    saveEditEvent, cancelEditEvent } = useEditEvent();

  const projects = allProjects && allProjects.filter((project: Project) => project.projectTypeId === 3);

  useEffect(() => {
    (async () => {
      if (eventId) {
        await loadEvent(`${eventId}`);
      }
    })();
  }, [eventId]);

  const handleSaveEvent = async (event: Event): Promise<boolean> => {
    const wasSucess: boolean = await saveEditEvent(event);
    if (!wasSucess) {
      return wasSucess;
    }
    await onReloadEvents();
    return wasSucess;
  }

  const handleCancel = () => {
    cancelEditEvent();
  }

  return (
      <>
        <EventForm event={event}
                     eventTypes={eventTypes}
                     projects={projects}
                     alertData={alertData}
                     mode="EDIT"
                     modalId="editEventForm"
                     showModal={showEditModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowEditModal}
                     onSaveEvent={handleSaveEvent}
                     onCancel={handleCancel} />
      </>
  );

}

export default EditEventFragment;