import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {ERROR_CODES} from "../../types/constants/error-codes";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {authActions} from "../../redux/slices/auth-slice";
import {TimeRecord} from "../../types/time-record/TimeRecord";
import {parseTimeRecordValues} from "../../utils/time-record-utils";

const useTimeRecordList = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData } = useFetch();

  const [timeRecords, setTimeRecords] = useState<TimeRecord[]>([]);
  const [selectedTimeRecordId, setSelectedTimeRecordId] = useState<number>(null);
  const [selectedTimeRecord, setSelectedTimeRecord] = useState<TimeRecord>(null);

  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);


  const initShowNewModal = (show: boolean) => {
    setShowNewModal(show);
  }

  const initShowEditModal = (show: boolean) => {
    setShowEditModal(show);
  }

  const initShowDeleteModal = (show: boolean) => {
    setShowDeleteModal(show);
  }

  const loadTimeRecords = async () => {
    const resp = await fetchData('/time-record/list', null, true);
    if (!resp) {
      return;
    }
    if (resp.errorCode && resp.errorCode === ERROR_CODES.LOGIN_INVALID_AUTH_TOKEN) {
      dispatch(authActions.logout());
      navigate('/login?eventType=logged_out');
      return;
    }
    if (resp.timeRecords) {
      resp.timeRecords.forEach((timeRecord: TimeRecord) => {
        parseTimeRecordValues(timeRecord);
      });
      setTimeRecords(resp.timeRecords);
    }
  }

  const initEditTimeRecord = (timeRecord: TimeRecord) => {
    setSelectedTimeRecordId(timeRecord.id);
  }

  const initDeleteTimeRecord = (timeRecord: TimeRecord) => {
    setSelectedTimeRecord(timeRecord);
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    timeRecords: timeRecords,
    selectedTimeRecordId: selectedTimeRecordId,
    selectedTimeRecord: selectedTimeRecord,
    showNewModal: showNewModal,
    showEditModal: showEditModal,
    showDeleteModal: showDeleteModal,
    initShowNewModal: initShowNewModal,
    initShowEditModal: initShowEditModal,
    initShowDeleteModal: initShowDeleteModal,
    loadTimeRecords: loadTimeRecords,
    initEditTimeRecord: initEditTimeRecord,
    initDeleteTimeRecord: initDeleteTimeRecord,
  };

}

export default useTimeRecordList;