import { format } from "date-fns";
import {Event} from "../../../types/event/Event";
import classes from "./EventsCalendarRow.module.scss";
import DATE_FORMATS from "../../../types/constants/date-formats";
import {CalendarDay} from "../../../types/calendar/CalendarDay";
import de from "date-fns/locale/de";

interface Props {
  date: Date;
}
const EventsCalendarHeading = ({ date }: Props) => {

  const monthYear = format(date, DATE_FORMATS.MONTH_YEAR, {locale: de});

  return (
      <>
        <tr>
          <th colSpan={3}>
            <h5>{monthYear}</h5>
          </th>
        </tr>
      </>
  );
}

export default EventsCalendarHeading;