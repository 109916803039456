import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {TimeRecord} from "../../types/time-record/TimeRecord";
import TimeRecordForm from "../../components/time-record/time-record-edit/TimeRecordForm";
import useNewTimeRecord from "../../hooks/time-record/new-time-record";
import {ProjectsState} from "../../types/state/ProjectsState";

interface Props {
  showNewModal: boolean,
  onInitShowNewModal: (show: boolean) => void;
  onReloadTimeRecords: () => void;
}
const NewTimeRecordFragment = ({ showNewModal, onInitShowNewModal, onReloadTimeRecords }: Props) => {

  const { projects }: ProjectsState = useSelector((state: any) => state.projects);

  const { isLoading, hasError,
    timeRecord, alertData,
    resetTimeRecord,
    saveNewTimeRecord, cancelNewTimeRecord } = useNewTimeRecord();

  useEffect(() => {
      resetTimeRecord();
  }, []);

  const handleSaveTimeRecord = async (timeRecord: TimeRecord) => {
    await saveNewTimeRecord(timeRecord);
    await onReloadTimeRecords();
    resetTimeRecord();
  }

  const handleCancelTimeRecord = () => {
    cancelNewTimeRecord();
    resetTimeRecord();
  }

  return (
      <>
        {/* must be present so that modal works */}
        <TimeRecordForm timeRecord={timeRecord}
                        projects={projects}
                     alertData={alertData}
                     mode="NEW"
                     modalId="newTimeRecordForm"
                     showModal={showNewModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowNewModal}
                     onSaveTimeRecord={handleSaveTimeRecord}
                     onCancel={handleCancelTimeRecord} />
      </>
  );
}

export default NewTimeRecordFragment;