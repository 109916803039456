import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {createPostRequest} from "../../utils/api-utils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/app-utils";
import {ERROR_CODES} from "../../types/constants/error-codes";
import {useNavigate, useSearchParams} from "react-router-dom";
import {pageActions} from "../../redux/slices/page-slice";
import {authActions} from "../../redux/slices/auth-slice";
import {useDispatch} from "react-redux";
import {TimeRecord} from "../../types/time-record/TimeRecord";
import useLabels from "../use-labels";
import labels from "../../types/constants/labels";
import {formatTimeRecordValues, parseTimeRecordValues} from "../../utils/time-record-utils";

const useEditTimeRecord = () => {
  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, hasError, fetchData } = useFetch();

  const [timeRecord, setTimeRecord] = useState<TimeRecord>(null);

  const [alertData, setAlertData] = useState<AlertData>(null);


  const loadTimeRecord = async (timeRecordId: string) => {
    const resp = await fetchData(`/time-record/${timeRecordId}`, null, true);
    if (!resp) {
      return;
    }
    if (resp.errorCode && resp.errorCode === ERROR_CODES.LOGIN_INVALID_AUTH_TOKEN) {
      dispatch(authActions.logout());
      navigate('/login?eventType=logged_out');
      return;
    }
    if (resp.timeRecord) {
      parseTimeRecordValues(resp.timeRecord);
      setTimeRecord(resp.timeRecord);
    }
  }

  const saveEditTimeRecord = async (timeRecord: TimeRecord) => {
    formatTimeRecordValues(timeRecord);

    const timeRecordData = {
      timeRecord: timeRecord,
    }
    const reqData = createPostRequest(timeRecordData);
    const respData = await fetchData('/time-record/update', reqData, true);

    const alert = handleError(respData, label);
    if (alert) {
      setAlertData(alert);
      return;
    }
    const successAlert = createSuccessAlert(label(labels.timeRecords.alerts.EDITED_SUCCESSFULLY, timeRecord.effort));
    dispatch(pageActions.initAlertData(successAlert));

    // navigate(`/cards/${timeRecord.cardId}`);
  }

  const cancelEditTimeRecord = () => {
    // navigate(`/cards/${card.id}`);
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    timeRecord: timeRecord,
    alertData: alertData,
    loadTimeRecord: loadTimeRecord,
    saveEditTimeRecord: saveEditTimeRecord,
    cancelEditTimeRecord: cancelEditTimeRecord,
  };

}

export default useEditTimeRecord;