import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TimeRecordForm from "../../components/time-record/time-record-edit/TimeRecordForm";
import useEditTimeRecord from "../../hooks/time-record/edit-time-record";
import {TimeRecord} from "../../types/time-record/TimeRecord";
import {ProjectsState} from "../../types/state/ProjectsState";

interface Props {
  timeRecordId: number;
  showEditModal: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onReloadTimeRecords: () => void;
}

const EditTimeRecordFragment = ({ timeRecordId, showEditModal, onInitShowEditModal, onReloadTimeRecords }: Props) => {

  const { projects }: ProjectsState = useSelector((state: any) => state.projects);

  const { isLoading, hasError,
    timeRecord, alertData,
    loadTimeRecord,
    saveEditTimeRecord, cancelEditTimeRecord } = useEditTimeRecord();

  useEffect(() => {
    (async () => {
      if (timeRecordId) {
        await loadTimeRecord(`${timeRecordId}`);
      }
    })();
  }, [timeRecordId]);

  const handleSaveTimeRecord = async (timeRecord: TimeRecord) => {
    await saveEditTimeRecord(timeRecord);
    await onReloadTimeRecords();
  }

  const handleCancel = () => {
    cancelEditTimeRecord();
  }

  return (
      <>
        <TimeRecordForm timeRecord={timeRecord}
                        projects={projects}
                     alertData={alertData}
                     mode="EDIT"
                     modalId="editTimeRecordForm"
                     showModal={showEditModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowEditModal}
                     onSaveTimeRecord={handleSaveTimeRecord}
                     onCancel={handleCancel} />
      </>
  );

}

export default EditTimeRecordFragment;