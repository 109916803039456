import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PageState} from "../../types/state/PageState";
import {AlertData} from "../../types/components/AlertData";

const initialState: PageState = {
  alertData: null,
}

const initAlertData = (pageState: PageState, action: PayloadAction<AlertData>) => {
  pageState.alertData = action.payload;
}

const clear = (pageState: PageState) => {
  pageState.alertData = null;
}

const pageSlice = createSlice({
  name: 'page',
  initialState: initialState,
  reducers: {
    initAlertData: initAlertData,
    clear: clear,
  },
});

export const pageReducer = pageSlice.reducer;
export const pageActions = pageSlice.actions;