import Alert from "./Alert";
import labels from "../../types/constants/labels";
import useLabels from "../../hooks/use-labels";

const DefaultErrorAlert = () => {

  const { getLabel: label } = useLabels();

  const alertData = { type: 'danger', message: label(labels.errors.UNKNOWN_ERROR)};

  return (
      <Alert alertData={alertData} />
  );
}

export default DefaultErrorAlert;