import useFetch from "../use-fetch";
import {useDispatch, useSelector} from "react-redux";
import {UserData} from "../../types/user/UserData";
import {Project} from "../../types/project/Project";
import {projectsActions} from "../../redux/slices/projects-slice";
import {ProjectType} from "../../types/project/ProjectType";
import {eventsActions} from "../../redux/slices/events-slice";
import {EventType} from "../../types/event/EventType";

const useDataStore = () => {
  const { isLoading, hasError, fetchData } = useFetch();
  const dispatch = useDispatch();

  const loadDataStore = async (userData: UserData) => {
    const projectTypes: ProjectType[] = await loadProjectTypes(userData);
    const projects: Project[] = await loadProjects(userData);
    const eventTypes: EventType[] = await loadEventTypes(userData);

    if (projectTypes) {
      dispatch(projectsActions.initProjectTypes(projectTypes));
    }
    if (projects) {
      dispatch(projectsActions.initProjects(projects));
    }
    if (eventTypes) {
      dispatch(eventsActions.initEventTypes(eventTypes));
    }
  };

  const loadProjectTypes = async (userData: UserData) => {
    const resp = await fetchData(`/project-type/list`, null, true, userData);
    if (resp && !resp.errorCode && resp.projectTypes) {
      return resp.projectTypes;
    }
  }

  const loadProjects = async (userData: UserData) => {
    const resp = await fetchData(`/project/list`, null, true, userData);
    if (resp && !resp.errorCode && resp.projects) {
      return resp.projects;
    }
  }

  const loadEventTypes = async (userData: UserData) => {
    const resp = await fetchData(`/event-type/list`, null, true, userData);
    if (resp && !resp.errorCode && resp.eventTypes) {
      return resp.eventTypes;
    }
  }

  return {
    loadDataStore: loadDataStore,
  };

}

export default useDataStore;