import {Project} from "../../../types/project/Project";
import {Link} from "react-router-dom";

interface Props {
  projects: Project[];
  projectId: number;
}
const EventProjectsNav = ({ projects, projectId }: Props) => {

  return (
      <>
        <ul className="nav nav-pills mb-3">
          {
            projects.map((project: Project) => (
              <li key={project.id}
                  className="nav-item">
                <Link to={`/events/${project.id}`}
                    className={`nav-link ${project.id === projectId ? 'active' : ''}`}>
                  {project.projectName}
                </Link>
              </li>
            ))
          }
        </ul>
      </>
  );
}
export default EventProjectsNav;