import {useRouteError} from "react-router-dom";
import MainNav from "../../components/_layout/MainNav";
import labels from "../../types/constants/labels";
import useLabels from "../../hooks/use-labels";
import {AuthState} from "../../types/state/AuthState";
import {useSelector} from "react-redux";
import Footer from "../../components/_layout/Footer";
import useLogin from "../../hooks/login/login";

const ErrorPage = () => {
  const { getLabel: label } = useLabels();

  const { isLoggedIn, userData, language }: AuthState = useSelector((state: any) => state.auth);

  const {
    logout,
    changeLanguage,
  } = useLogin();

  const error = useRouteError();
  console.error(error);

  const title = label(labels.errors.ERROR_OCCURRED);

  return (
      <>
        <header>
          <MainNav isLoggedIn={isLoggedIn} userData={userData} language={language}
                   onLogout={logout} onChangeLanguage={changeLanguage} />
        </header>

        <main className="container mt-4">
          <h3>{title}</h3>
        </main>

        <Footer />

      </>
  );
};

export default ErrorPage;