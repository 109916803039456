import React from 'react';

import {RouterProvider} from "react-router-dom";
import appRouter from "./router/app-router";
import reduxStore from "./redux/redux-store";
import {Provider} from "react-redux";

// import { registerLocale } from "react-datepicker";
// import de from "date-fns/locale/de"; // the locale you want
// registerLocale("de", de);

function App() {

  console.log('App re-evaluated.');

  return (
      <Provider store={reduxStore}>
        <RouterProvider router={appRouter} />
      </Provider>

  );
}

export default App;
