import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Event} from "../../types/event/Event";
import EventForm from "../../components/event/event-edit/EventForm";
import useNewEvent from "../../hooks/event/new-event";
import {ProjectsState} from "../../types/state/ProjectsState";
import {EventsState} from "../../types/state/EventsState";
import {Project} from "../../types/project/Project";
import {DEFAULT_COLORS} from "../../types/constants/colors";
import {Color} from "../../types/_general/Color";

interface Props {
  eventStart: Date;
  projectId: number,
  showNewModal: boolean,
  onInitShowNewModal: (show: boolean) => void;
  onReloadEvents: () => void;
}
const NewEventFragment = ({ eventStart, projectId,  showNewModal, onInitShowNewModal, onReloadEvents }: Props) => {

  const { eventTypes }: EventsState = useSelector((state: any) => state.events);
  const { projects: allProjects }: ProjectsState = useSelector((state: any) => state.projects);

  const colors: Color[] = DEFAULT_COLORS;

  const { isLoading, hasError,
    event, alertData,
    resetEvent,
    saveNewEvent, cancelNewEvent } = useNewEvent();

  const projects = allProjects && allProjects.filter((project: Project) => project.projectTypeId === 3);

  useEffect(() => {
    if (projectId) {
      if (eventStart) {
        resetEvent(colors, eventTypes, projectId, eventStart);
      } else {
        resetEvent(colors, eventTypes, projectId);
      }
    }
  }, [projectId, eventStart]);


  const handleSaveEvent = async (event: Event): Promise<boolean> => {
    const wasSucess: boolean = await saveNewEvent(event);
    if (!wasSucess) {
      return wasSucess;
    }
    await onReloadEvents();
    resetEvent(colors, eventTypes, projectId);
    return wasSucess;
  }

  const handleCancelEvent = () => {
    cancelNewEvent();
    resetEvent(colors, eventTypes, projectId);
  }

  return (
      <>
        {/* must be present so that modal works */}
        <EventForm event={event}
                     eventTypes={eventTypes}
                     projects={projects}
                     alertData={alertData}
                     mode="NEW"
                     modalId="newEventForm"
                     showModal={showNewModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowNewModal}
                     onSaveEvent={handleSaveEvent}
                     onCancel={handleCancelEvent} />
      </>
  );
}

export default NewEventFragment;