import {Event} from "../../../types/event/Event";
import classes from "./EventItem.module.scss";
import {useState} from "react";
import Button from "../../_general/Button";

interface Props {
  event: Event;
  isWeekend: boolean;
  isHoliday: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditEvent: (event: Event) => void;
  onInitDeleteEvent: (event: Event) => void;
}
const EventItem = ({ event, isWeekend, isHoliday, onInitShowEditModal, onInitShowDeleteModal, onInitEditEvent, onInitDeleteEvent  }: Props) => {

  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);

  const handleEditClick = () => {
    onInitShowEditModal(true);
    onInitEditEvent(event);
  }

  const handleDeleteClick = () => {
    onInitShowDeleteModal(true);
    onInitDeleteEvent(event);
  }

  const handleMouseOver = () => {
    setShowDeleteButton(true);
  }

  const handleMouseOut = () => {
    setShowDeleteButton(false);
  }

  return (
      <>
        <div className={`${classes.event} ${isWeekend || isHoliday ? classes.transparent : ''} ${event.eventTypeId === 2 ? classes.eventRight : ''} me-3`}
             style={{backgroundColor: event.color ? `#${event.color}` : ''}}
             onMouseOver={handleMouseOver}
             onMouseOut={handleMouseOut}>
          <div className={classes.eventLabel} onClick={handleEditClick}>
            {event.eventName}
          </div>
          {showDeleteButton &&
              <Button classes={`btn-light btn-sm ${classes.deleteButton} ms-2`} icon="x"
                      onClick={handleDeleteClick}
              />
          }
        </div>
      </>
  );
}

export default EventItem;