import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import TimeRecordForm from "../../components/time-record/time-record-edit/TimeRecordForm";
import useEditTimeRecord from "../../hooks/time-record/edit-time-record";
import {TimeRecord} from "../../types/time-record/TimeRecord";
import useDeleteTimeRecord from "../../hooks/time-record/delete-time-record";
import ModalFrame from "../../components/_general/ModalFrame";
import Button from "../../components/_general/Button";
import Alert from "../../components/_general/Alert";
import DefaultErrorAlert from "../../components/_general/DefaultErrorAlert";
import useLabels from "../../hooks/use-labels";
import labels from "../../types/constants/labels";

interface Props {
  timeRecord: TimeRecord;
  showDeleteModal: boolean;
  onInitShowDeleteModal: (show: boolean) => void;
  onReloadTimeRecords: () => void;
}

const DeleteTimeRecordFragment = ({ timeRecord, showDeleteModal, onInitShowDeleteModal, onReloadTimeRecords }: Props) => {

  const { getLabel: label } = useLabels();

  const { isLoading, hasError,
    alertData,
    deleteTimeRecord } = useDeleteTimeRecord();

  const handleDeleteTimeRecord = async () => {
    await deleteTimeRecord(timeRecord);
    await onReloadTimeRecords();
    onInitShowDeleteModal(false);
  }

  const handleCancel = () => {
    onInitShowDeleteModal(false);
  }

  return (
      <>
        <ModalFrame id="deleteTimeRecordForm"
                    showModal={showDeleteModal}
                    title={label(labels.timeRecords.actions.DELETE_TIME_RECORD)}
                    onCancel={handleCancel}>
          {alertData &&
              <Alert alertData={alertData} />
          }
          {hasError &&
              <DefaultErrorAlert />
          }
          {timeRecord &&
              <>
              <div className="modal-body">
                <p>{label(labels.timeRecords.actions.REALLY_DELETE_TIME_RECORD, timeRecord.effort)}</p>
              </div>
                <div className="modal-footer">
                  <Button classes="btn-default" icon="x" label={label(labels.actions.CANCEL)}
                      onClick={() => { onInitShowDeleteModal(false) } } />
                  <Button classes="btn-primary" icon="trash" label={label(labels.actions.DELETE)} onClick={handleDeleteTimeRecord} />
                </div>
              </>
          }
        </ModalFrame>
      </>
  );

}

export default DeleteTimeRecordFragment;