import {differenceInMinutes, format} from "date-fns";
import {TimeRecord} from "../../../types/time-record/TimeRecord";
import Button from "../../_general/Button";
import classes from "./TimeRecordRow.module.scss";
import DATE_FORMATS from "../../../types/constants/date-formats";
import {getDurationOfTimeRecord} from "../../../utils/time-record-utils";

interface Props {
  timeRecord: TimeRecord;
  nextTimeRecord?: TimeRecord;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditTimeRecord: (timeRecord: TimeRecord) => void;
  onInitDeleteTimeRecord: (timeRecord: TimeRecord) => void;
}
const TimeRecordRow = ({ timeRecord, nextTimeRecord, onInitShowEditModal, onInitShowDeleteModal, onInitEditTimeRecord, onInitDeleteTimeRecord }: Props) => {

  const recordDateFormatted: string = format(timeRecord.recordDateAsDate, DATE_FORMATS.DATE_DE);
  const nextRecordDateFormatted: string = nextTimeRecord ? format(nextTimeRecord.recordDateAsDate, DATE_FORMATS.DATE_DE) : null;

  const [durationHours, durationMinutes] = getDurationOfTimeRecord(timeRecord);

  const isTimeRecordOfToday = (recordDateFormatted: string):boolean => {
    return recordDateFormatted === format(new Date(), DATE_FORMATS.DATE_DE);
  }

  const handleEditClick = () => {
    onInitShowEditModal(true);
    onInitEditTimeRecord(timeRecord);
  }

  const handleDeleteClick = () => {
    onInitShowDeleteModal(true);
    onInitDeleteTimeRecord(timeRecord);
  }

  return (
      <>
        <tr className={classes.row}>
          <th scope="row">{timeRecord.id}</th>
          <td>{recordDateFormatted}</td>
          <td>
            {format(timeRecord.recordFromAsDate, DATE_FORMATS.TIME)} - {format(timeRecord.recordToAsDate, DATE_FORMATS.TIME)}
          </td>
          <td>
            {durationHours > 0 &&
                `${durationHours} h `
            }
            {durationMinutes > 0 &&
                `${durationMinutes} min`
            }
          </td>
          <td>{timeRecord.effort}</td>
          <td>
            <div className={`${classes.colorBadge} me-2`}
                 style={{backgroundColor: `#${timeRecord.project.color}`}} />
            <small>{timeRecord.project.projectName}</small></td>
          <td className={`text-end ${classes.buttonCol}`}>
            <Button classes="btn-light btn-sm me-2" icon="pencil"
                    onClick={handleEditClick}
                // data-bs-toggle="modal" data-bs-target="#cardEditionFormModal"
            />
            <Button classes="btn-light btn-sm" icon="trash"
                    onClick={handleDeleteClick}
                // data-bs-toggle="modal" data-bs-target="#cardEditionFormModal"
            />
          </td>
        </tr>
        {(nextRecordDateFormatted && recordDateFormatted !== nextRecordDateFormatted) &&
          <tr className={classes.emptyRow}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        }
      </>
  );
}

export default TimeRecordRow;