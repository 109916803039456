import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {createPostRequest} from "../../utils/api-utils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/app-utils";
import {useNavigate, useSearchParams} from "react-router-dom";
import {pageActions} from "../../redux/slices/page-slice";
import {authActions} from "../../redux/slices/auth-slice";
import {useDispatch} from "react-redux";
import {ProjectType} from "../../types/project/ProjectType";
import {Project} from "../../types/project/Project";
import useLabels from "../use-labels";
import labels from "../../types/constants/labels";

const useNewProject = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData } = useFetch();

  const [project, setProject] = useState<Project>(null);

  const [alertData, setAlertData] = useState<AlertData>(null);

  const resetProject = () => {
    const newProject: Project = {
      id: null,
      projectName: null,
      description: null,
      sortOrder: null,
      // color: null,
      // projectTypeId: projectTypes && projectTypes.length > 0 ? projectTypes[0].id : null,
    };
    setProject(newProject);
  }

  const saveNewProject = async (project: Project) => {
    const projectData = {
      project: project,
    }
    const reqData = createPostRequest(projectData);
    const respData = await fetchData('/project/create', reqData, true);

    const alert = handleError(respData, label);
    if (alert) {
      setAlertData(alert);
      return;
    }
    const successAlert = createSuccessAlert(label(labels.projects.alerts.ADDED_SUCCESSFULLY, project.projectName));
    dispatch(pageActions.initAlertData(successAlert));

    // navigate(`/cards/${project.cardId}`);
  }

  const cancelNewProject = () => {
    // navigate(`/cards/${card.id}`);
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    project: project,
    alertData: alertData,
    resetProject: resetProject,
    saveNewProject: saveNewProject,
    cancelNewProject: cancelNewProject,
  };

}

export default useNewProject;