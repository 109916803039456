import {AlertData} from "../types/components/AlertData";
import labels from "../types/constants/labels";
import {UserData} from "../types/user/UserData";
import FEATURES from "../types/constants/features";
import {ERROR_CODE_LABELS, ERROR_CODES} from "../types/constants/error-codes";

export const handleError = (respData: any, label: any): AlertData => {
  if (!respData || !respData.errorCode) {
    return;
  }

  let message = label(ERROR_CODE_LABELS[respData.errorCode]);

  if (!message) {
    if (respData.message) {
      message = respData.message;
    } else {
      message = label(ERROR_CODE_LABELS.UNKNOWN_ERROR);
    }
  }

  const alert: AlertData = {
    type: 'danger',
    message: message,
  };
  return alert;
}

export const createWarningAlert = (message: string): AlertData => {
  return {
    type: 'warning',
    message: message,
  };
}

export const createSuccessAlert = (message: string): AlertData => {
  return {
    type: 'success',
    message: message,
  };
}

export const hasFeature = (feature: string, userData: UserData) => {
  if (!feature || !userData) {
    return;
  }
  const { features } = userData;
  return features && (features.includes(FEATURES.ALL) || features.includes(feature));
}