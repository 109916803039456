interface Props {
  id: string;
  showModal: boolean;
  title: string;
  scrollable?: boolean;
  children: any;
  onCancel: () => void;
}
const ModalFrame = ({ id, showModal, title, scrollable = false, children, onCancel }: Props) => {

  return (
      <>
        {showModal &&
          <div className="modal-backdrop fade show" />
        }
        <div id={id} className={`modal fade ${showModal ? 'show' : ''}`} data-bs-keyboard="false"
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
          <div className={`modal-dialog modal-dialog-centered ${scrollable ? 'modal-dialog-scrollable' : ''}`}>
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="staticBackdropLabel">{title}</h1>
                <button type="button" onClick={onCancel}
                        className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              {children}
            </div>
          </div>
        </div>
      </>
  );
}

export default ModalFrame;