import {Link} from "react-router-dom";
import {BreacrumbData} from "../../types/components/BreacrumbData";

interface Props {
  pages: BreacrumbData[];
}
const Breadcrumb = ({ pages }: Props) => {
  return (
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {pages.map((page: BreacrumbData, i: number) => (
              (i === pages.length - 1) ?
                (
                  <li className="breadcrumb-item active" key={i}>
                    {page.item}
                  </li>
                )
            : (
                    <li className="breadcrumb-item" key={i}>
                      <Link to={page.to}>{page.item}</Link>
                    </li>
                )
          ))}
        </ol>
      </nav>
  );
};

export default Breadcrumb;