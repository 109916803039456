import TimeRecordRow from "./TimeRecordRow";
import Spinner from "../../_general/Spinner";
import {TimeRecord} from "../../../types/time-record/TimeRecord";
import React from "react";
import useLabels from "../../../hooks/use-labels";
import labels from "../../../types/constants/labels";

interface Props {
  timeRecords: TimeRecord[];
  isLoading: boolean;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitEditTimeRecord: (timeRecord: TimeRecord) => void;
  onInitDeleteTimeRecord: (timeRecord: TimeRecord) => void;
}
const TimeRecordsTable = ({ timeRecords, isLoading, onInitShowEditModal, onInitShowDeleteModal, onInitEditTimeRecord, onInitDeleteTimeRecord }: Props) => {

  const { getLabel: label } = useLabels();

  if (isLoading) {
    return (
        <>
          <Spinner />
          <p className="text-muted">{label(labels.timeRecords.titles.TIME_RECORDS_LOADED)}</p>
        </>
    );
  }
  if (!timeRecords || timeRecords.length === 0) {
    return (
        <p className="text-muted">{label(labels.timeRecords.titles.NO_TIME_RECORDS_FOUND)}</p>
    );
  }

  return (
      <>
        <table className="table mt-4">
          <thead>
          <tr>
            <th scope="col">{label(labels.timeRecords.fields.ID)}</th>
            <th scope="col">{label(labels.timeRecords.fields.RECORD_DATE)}</th>
            <th scope="col">{label(labels.timeRecords.fields.RECORD_FROM)} - {label(labels.timeRecords.fields.RECORD_TO)}</th>
            <th scope="col">{label(labels.timeRecords.fields.RECORD_DURATION)}</th>
            <th scope="col">{label(labels.timeRecords.fields.EFFORT)}</th>
            <th scope="col">{label(labels.timeRecords.fields.PROJECT)}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {timeRecords.map((timeRecord: TimeRecord, index: number) => (
              <TimeRecordRow
                key={timeRecord.id}
                timeRecord={timeRecord}
                nextTimeRecord={(index + 1) < timeRecords.length ? timeRecords[index + 1] : null}
                onInitShowEditModal={onInitShowEditModal}
                onInitShowDeleteModal={onInitShowDeleteModal}
                onInitEditTimeRecord={onInitEditTimeRecord}
                onInitDeleteTimeRecord={onInitDeleteTimeRecord} />
              ))}
          </tbody>

        </table>
      </>
  );

}

export default TimeRecordsTable;