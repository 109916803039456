import {useCallback, useState} from "react";
import {addAuthData} from "../utils/api-utils";
import {useSelector} from "react-redux";
import {AuthState} from "../types/state/AuthState";
import {UserData} from "../types/user/UserData";

const useFetch = () => {

  const authState: AuthState = useSelector((state: any) => state.auth);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const apiUrl = process.env.REACT_APP_REST_API_URL;
  const fetchData = useCallback(async (path: string, reqData: any, authRequired: boolean, userData?: UserData): Promise<any> => {
    setIsLoading(true);
    setHasError(false);

    const url = `${apiUrl}${path}`;
    const req = {
      method: reqData?.method ? reqData.method : 'GET',
      headers: reqData?.headers ? reqData.headers : {},
      body: reqData?.body ? JSON.stringify(reqData.body) : null,
    }
    if (authRequired) {
      addAuthData(req.headers, userData ?  userData : authState.userData);
    }
    try {
      const resp = await fetch(url, req);

      if (!resp.ok && resp.status !== 422 && resp.status !== 401) {
        setHasError(true);
        setIsLoading(false);
        console.log('Resp Status: ' + resp.status);
        console.error('An error occurred while fetching data.')
        return;
      }

      const respData = await resp.json();

      setIsLoading(false);

      // console.log(respData);
      return respData;

    } catch (error) {
      console.error('An error occurred while fetching data.');
      console.error(error);

      setHasError(true);
      setIsLoading(false);

      return null;
    }


  }, []);

  return {
    isLoading: isLoading,
    hasError: hasError,
    fetchData: fetchData,
  };

};

export default useFetch;