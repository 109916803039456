import React, {useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ProjectForm from "../../components/project/project-edit/ProjectForm";
import {ProjectsState} from "../../types/state/ProjectsState";
import useEditProject from "../../hooks/project/edit-project";
import {Project} from "../../types/project/Project";
import useDeleteProject from "../../hooks/project/delete-project";
import ModalFrame from "../../components/_general/ModalFrame";
import Button from "../../components/_general/Button";
import Alert from "../../components/_general/Alert";
import DefaultErrorAlert from "../../components/_general/DefaultErrorAlert";
import useLabels from "../../hooks/use-labels";
import labels from "../../types/constants/labels";

interface Props {
  project: Project;
  showDeleteModal: boolean;
  onInitShowDeleteModal: (show: boolean) => void;
  onReloadProjects: () => void;
}

const DeleteProjectFragment = ({ project, showDeleteModal, onInitShowDeleteModal, onReloadProjects }: Props) => {

  const { getLabel: label } = useLabels();

  const { isLoading, hasError,
    alertData,
    deleteProject } = useDeleteProject();

  const handleDeleteProject = async () => {
    await deleteProject(project);
    await onReloadProjects();
    onInitShowDeleteModal(false);
  }

  const handleCancel = () => {
    onInitShowDeleteModal(false);
  }

  return (
      <>
        <ModalFrame id="deleteProjectForm"
                    showModal={showDeleteModal}
                    title={label(labels.projects.actions.DELETE_PROJECT)}
                    onCancel={handleCancel}>
          {alertData &&
              <Alert alertData={alertData} />
          }
          {hasError &&
              <DefaultErrorAlert />
          }
          {project &&
              <>
              <div className="modal-body">
                <p>{label(labels.projects.actions.REALLY_DELETE_PROJECT, project.projectName)}</p>
              </div>
                <div className="modal-footer">
                  <Button classes="btn-default" icon="x" label={label(labels.actions.CANCEL)}
                      onClick={() => { onInitShowDeleteModal(false) } } />
                  <Button classes="btn-primary" icon="check" label={label(labels.actions.SAVE)} onClick={handleDeleteProject} />
                </div>
              </>
          }
        </ModalFrame>
      </>
  );

}

export default DeleteProjectFragment;