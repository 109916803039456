import {BreacrumbData} from "../../types/components/BreacrumbData";

interface Props {
  icon: string;
  classes?: string;
}
const Icon = ({ icon, classes }: Props) => {
  return (
    <i className={`bi-${icon} ${classes ? classes : ''}`}></i>
  );
}

export default Icon;