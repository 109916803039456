import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import ModalFrame from "../../_general/ModalFrame";
import Alert from "../../_general/Alert";
import DefaultErrorAlert from "../../_general/DefaultErrorAlert";
import {AlertData} from "../../../types/components/AlertData";
import Button from "../../_general/Button";
import {Event} from "../../../types/event/Event";
import {DEFAULT_COLORS} from "../../../types/constants/colors";
import {Color} from "../../../types/_general/Color";
import useLabels from "../../../hooks/use-labels";
import labels from "../../../types/constants/labels";
import {Project} from "../../../types/project/Project";
import {convertToEvent, parseEventValues, formatEventValues} from "../../../utils/event-utils";
import {EventType} from "../../../types/event/EventType";

interface Props {
  event: Event;
  eventTypes: EventType[],
  projects: Project[];
  alertData: AlertData;
  mode: string;
  modalId: string;
  showModal: boolean;
  isLoading: boolean;
  hasError: boolean;
  onInitShowModal: (show: boolean) => void;
  onSaveEvent: (event: Event) => Promise<boolean>;
  onCancel: () => void;
}

const EventForm = ({ event, eventTypes, projects, alertData, mode, modalId, showModal,
                           isLoading, hasError, onInitShowModal, onSaveEvent, onCancel }: Props) => {

  const { getLabel: label } = useLabels();

  const {
    control,
    register,
    handleSubmit: onSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm<Event>();

  const colors: Color[] = DEFAULT_COLORS;

  useEffect(() => {
    reset(event);
  }, [event]);


  const handleSubmit = async (formEvent: Event) => {
    formatEventValues(formEvent);

    const event: Event = convertToEvent(formEvent);

    const wasSuccess: boolean = await onSaveEvent(event);
    if (wasSuccess) {
      onInitShowModal(false);
    }
  }

  const handleCancel = () => {
    onCancel();
    onInitShowModal(false);
  }

  return (
      <>
          <ModalFrame id={modalId} showModal={showModal}
                      title={mode === 'EDIT' ? label(labels.events.actions.EDIT_EVENT) :
                          label(labels.events.actions.NEW_EVENT)}
                      scrollable={true}
                      onCancel={handleCancel}>
            {isLoading &&
              <p>{label(labels.events.titles.EVENT_LOADED)}</p>
            }

            {mode === 'EDIT' && !event &&
              <p>{label(labels.events.titles.NO_EVENT_FOUND)}</p>
            }

            {alertData &&
                <div className="p-3">
                  <Alert alertData={alertData} />
                </div>
            }
            {hasError &&
                <div className="p-3">
                  <DefaultErrorAlert />
                </div>
            }

            {event &&
              <form onSubmit={onSubmit(handleSubmit)}>
                <div className="modal-body">

                  <p className="text-muted">
                    <small>{label(labels.events.titles.EVENTS_HINT)}</small>
                  </p>

                  {mode === 'EDIT' &&
                    <input type="hidden" id={`${modalId}-id`}
                           {...register("id", {})}
                           defaultValue={event.id}
                           className="form-control" />
                  }

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-eventName`} className="form-label">
                      {label(labels.events.fields.NAME)}
                    </label>
                    <input type="text" id={`${modalId}-eventName`}
                           {...register("eventName", { required: true, maxLength: 255, pattern: /^(?! ).*(?<! )$/ })}
                           className={`form-control ${errors.eventName ? 'is-invalid' : ''}`}
                           placeholder={label(labels.events.fields.NAME)} required />
                    {errors.eventName &&
                        <div className="invalid-feedback">
                          {label(labels.events.fields.NAME_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-eventStartFormatted`} className="form-label">
                      {label(labels.events.fields.EVENT_START)}
                    </label>
                    <input type="date" id={`${modalId}-eventStartFormatted`}
                           {...register("eventStartFormatted", { required: true })}
                           className={`form-control ${errors.eventStartFormatted ? 'is-invalid' : ''}`}
                           placeholder={label(labels.events.fields.EVENT_START)} required />
                    {errors.eventStartFormatted &&
                        <div className="invalid-feedback">
                          {label(labels.events.fields.EVENT_START_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-eventEndFormatted`} className="form-label">
                      {label(labels.events.fields.EVENT_END)}
                    </label>
                    <input type="date" id={`${modalId}-eventEndFormatted`}
                           {...register("eventEndFormatted")}
                           className={`form-control ${errors.eventEndFormatted ? 'is-invalid' : ''}`}
                           placeholder={label(labels.events.fields.EVENT_END)} />
                    {errors.eventEndFormatted &&
                        <div className="invalid-feedback">
                          {label(labels.events.fields.EVENT_END_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-color`} className="form-label">
                      {label(labels.projects.fields.COLOR)}
                    </label>
                    <select id={`${modalId}-color`} className="form-select"
                            {...register("color")} >
                      {colors.map((color: any, i: number) => (
                          <option key={i}
                                  value={color.code}>
                            {color.label}
                          </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-eventTypeId`} className="form-label">
                      {label(labels.events.fields.EVENT_TYPE)}
                    </label>
                    <select id={`${modalId}-eventTypeId`} className="form-select"
                            {...register("eventTypeId")} >
                      {eventTypes.map((eventType: EventType) => (
                          <option key={eventType.id}
                                  value={eventType.id}>
                            {eventType.eventTypeName}
                          </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-projectId`} className="form-label">
                      {label(labels.events.fields.PROJECT)}
                    </label>
                    <select id={`${modalId}-projectId`} className="form-select"
                            {...register("projectId")} >
                      {projects.map((project: Project) => (
                          <option key={project.id}
                                  value={project.id}>
                            {project.projectName}
                          </option>
                      ))}
                    </select>
                  </div>

                </div>
                <div className="modal-footer">
                  <Button classes="btn-default" icon="x" label={label(labels.actions.CANCEL)}
                          onClick={handleCancel} />
                  <Button type="submit" classes="btn-primary" icon="check" label={label(labels.actions.SAVE)} />
                </div>
              </form>
            }
          </ModalFrame>
      </>
  );
}

export default EventForm;