import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {createPostRequest} from "../../utils/api-utils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/app-utils";
import {Project} from "../../types/project/Project";
import {useDispatch} from "react-redux";
import {pageActions} from "../../redux/slices/page-slice";
import useLabels from "../use-labels";
import labels from "../../types/constants/labels";

const useDeleteProject = () => {

  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const { isLoading, hasError, fetchData } = useFetch();

  const [alertData, setAlertData] = useState<AlertData>(null);

  const deleteProject = async  (project: Project) => {
    const reqData = createPostRequest(null);
    const respData = await fetchData(`/project/delete/${project.id}`, reqData, true);
    if (!respData) {
      return;
    }

    const alert: AlertData = handleError(respData, label);
    if (alert) {
      setAlertData(alert);
      return;
    }

    const successAlert: AlertData = createSuccessAlert(label(labels.projects.alerts.DELETED_SUCCESSFULLY, project.projectName));
    dispatch(pageActions.initAlertData(successAlert));
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    alertData: alertData,
    deleteProject: deleteProject,
  };

}

export default useDeleteProject;