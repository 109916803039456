import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProjectsState} from "../../types/state/ProjectsState";
import {Project} from "../../types/project/Project";
import {ProjectType} from "../../types/project/ProjectType";

const initialState: ProjectsState = {
  projectTypes: [],
  projects: [],
}

const initProjectTypes = (projectsState: ProjectsState, action: PayloadAction<ProjectType[]>) => {
  projectsState.projectTypes = action.payload;
}

const initProjects = (projectsState: ProjectsState, action: PayloadAction<Project[]>) => {
  projectsState.projects = action.payload;
}

const clear = (projectsState: ProjectsState) => {
  projectsState.projectTypes = [];
  projectsState.projects = [];
}

const projectsSlice = createSlice({
  name: 'projects',
  initialState: initialState,
  reducers: {
    initProjects: initProjects,
    initProjectTypes: initProjectTypes,
    clear: clear,
  },
});

export const projectsReducer = projectsSlice.reducer;
export const projectsActions = projectsSlice.actions;