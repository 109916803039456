import React, {useContext, useEffect, useState} from "react";
import {Event} from "../../types/event/Event";
import useDeleteEvent from "../../hooks/event/delete-event";
import ModalFrame from "../../components/_general/ModalFrame";
import Button from "../../components/_general/Button";
import Alert from "../../components/_general/Alert";
import DefaultErrorAlert from "../../components/_general/DefaultErrorAlert";
import useLabels from "../../hooks/use-labels";
import labels from "../../types/constants/labels";
import {ProjectsState} from "../../types/state/ProjectsState";
import {useSelector} from "react-redux";
import {EventsState} from "../../types/state/EventsState";

interface Props {
  event: Event;
  showDeleteModal: boolean;
  onInitShowDeleteModal: (show: boolean) => void;
  onReloadEvents: () => void;
}

const DeleteEventFragment = ({ event, showDeleteModal, onInitShowDeleteModal, onReloadEvents }: Props) => {

  const { getLabel: label } = useLabels();

  const { isLoading, hasError,
    alertData,
    deleteEvent } = useDeleteEvent();

  const handleDeleteEvent = async () => {
    await deleteEvent(event);
    await onReloadEvents();
    onInitShowDeleteModal(false);
  }

  const handleCancel = () => {
    onInitShowDeleteModal(false);
  }

  return (
      <>
        <ModalFrame id="deleteEventForm"
                    showModal={showDeleteModal}
                    title={label(labels.events.actions.DELETE_EVENT)}
                    onCancel={handleCancel}>
          {alertData &&
              <Alert alertData={alertData} />
          }
          {hasError &&
              <DefaultErrorAlert />
          }
          {event &&
              <>
              <div className="modal-body">
                <p>{label(labels.events.actions.REALLY_DELETE_EVENT, event.eventName)}</p>
              </div>
                <div className="modal-footer">
                  <Button classes="btn-default" icon="x" label={label(labels.actions.CANCEL)}
                      onClick={() => { onInitShowDeleteModal(false) } } />
                  <Button classes="btn-primary" icon="trash" label={label(labels.actions.DELETE)} onClick={handleDeleteEvent} />
                </div>
              </>
          }
        </ModalFrame>
      </>
  );

}

export default DeleteEventFragment;