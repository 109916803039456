import {Link} from "react-router-dom";
import Icon from "./Icon";

interface Props {
  type?: 'button' | 'submit' | 'reset';
  classes?: string;
  icon?: string;
  label?: string;
  onClick?: () => void;
}
const Button = ({ type = 'button', classes, icon, label, onClick, ...props }: Props) => {
  return (
      <button type={type} className={`btn ${classes ? classes : ''}`} onClick={onClick} {...props} >
        {icon &&
            <Icon icon={icon} classes={label ? 'me-2': ''} />
        }
        {label ? label : ''}
      </button>
  );
}

export default Button;