import {useSelector} from "react-redux";
import {AuthState} from "../types/state/AuthState";

const useLabels = () => {

  const { language }: AuthState = useSelector((state: any) => state.auth);

  const getLabel = (label: any, ...params: string[]): string => {
    if (!label) {
      return null;
    }
    let labelValue: string = label[language.toLowerCase()];
    if (!labelValue) {
      labelValue = label['de'];
    }
    if (params) {
      params.forEach((param, i) => {
        labelValue = labelValue.replace(`{${i}}`, param);
      });
    }
    return labelValue;
  };

  return {
    getLabel: getLabel,
  };

};

export default useLabels;