import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {ProjectsState} from "../../types/state/ProjectsState";
import {Project} from "../../types/project/Project";
import ProjectForm from "../../components/project/project-edit/ProjectForm";
import useNewProject from "../../hooks/project/new-project";

interface Props {
  showNewModal: boolean,
  onInitShowNewModal: (show: boolean) => void;
  onReloadProjects: () => void;
}
const NewProjectFragment = ({ showNewModal, onInitShowNewModal, onReloadProjects }: Props) => {

  const { projectTypes }: ProjectsState = useSelector((state: any) => state.projects);

  const { isLoading, hasError,
    project, alertData,
    resetProject,
    saveNewProject, cancelNewProject } = useNewProject();

  useEffect(() => {
      resetProject();
  }, []);

  const handleSaveProject = async (project: Project) => {
    await saveNewProject(project);
    await onReloadProjects();
    resetProject();
  }

  const handleCancelProject = () => {
    cancelNewProject();
    resetProject();
  }

  return (
      <>
        {/* must be present so that modal works */}
        <ProjectForm project={project}
                     projectTypes={projectTypes}
                     alertData={alertData}
                     mode="NEW"
                     modalId="newProjectForm"
                     showModal={showNewModal}
                     isLoading={isLoading}
                     hasError={hasError}
                     onInitShowModal={onInitShowNewModal}
                     onSaveProject={handleSaveProject}
                     onCancel={handleCancelProject} />
      </>
  );
}

export default NewProjectFragment;