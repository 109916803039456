import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import ModalFrame from "../../_general/ModalFrame";
import Alert from "../../_general/Alert";
import DefaultErrorAlert from "../../_general/DefaultErrorAlert";
import {AlertData} from "../../../types/components/AlertData";
import Button from "../../_general/Button";
import {ProjectType} from "../../../types/project/ProjectType";
import {Project} from "../../../types/project/Project";
import {DEFAULT_COLORS} from "../../../types/constants/colors";
import {Color} from "../../../types/_general/Color";
import useLabels from "../../../hooks/use-labels";
import labels from "../../../types/constants/labels";

interface Props {
  project: Project;
  projectTypes: ProjectType[];
  alertData: AlertData;
  mode: string;
  modalId: string;
  showModal: boolean;
  isLoading: boolean;
  hasError: boolean;
  onInitShowModal: (show: boolean) => void;
  onSaveProject: (project: Project) => void;
  onCancel: () => void;
}

const ProjectForm = ({ project, projectTypes, alertData, mode, modalId, showModal,
                           isLoading, hasError, onInitShowModal, onSaveProject, onCancel }: Props) => {

  const { getLabel: label } = useLabels();

  const {
    register,
    handleSubmit: onSubmit,
    watch,
    formState: { errors },
    reset
  } = useForm<Project>();

  const colors: Color[] = DEFAULT_COLORS;

  useEffect(() => {
    reset(project);
  }, [project]);


  const handleSubmit = (formProject: Project) => {
    const project: Project = convertToProject(formProject);

    onSaveProject(project);
    onInitShowModal(false);

  }

  const handleCancel = () => {
    onCancel();
    onInitShowModal(false);
  }

  const convertToProject = (formProject: Project): Project => {
    const {
      id,
      projectName,
      description,
      sortOrder,
      color,
      projectTypeId,
    } = formProject;

    const project: Project = {
      id: id ? Number(id) : null,
      projectName: projectName || null,
      description: description || null,
      sortOrder: sortOrder || sortOrder === 0 ? Number(sortOrder) : null,
      color: color || null,
      projectTypeId: Number(projectTypeId),
    }
    return project;
  }

  return (
      <>
          <ModalFrame id={modalId} showModal={showModal}
                      title={mode === 'EDIT' ? label(labels.projects.actions.EDIT_PROJECT) :
                          label(labels.projects.actions.NEW_PROJECT)}
                      scrollable={true}
                      onCancel={handleCancel}>
            {isLoading &&
              <p>{label(labels.projects.titles.PROJECT_LOADED)}</p>
            }

            {mode === 'EDIT' && !project &&
              <p>{label(labels.projects.titles.NO_PROJECT_FOUND)}</p>
            }

            {alertData &&
                <Alert alertData={alertData} />
            }
            {hasError &&
                <DefaultErrorAlert />
            }

            {project &&
              <form onSubmit={onSubmit(handleSubmit)}>
                <div className="modal-body">

                  {mode === 'EDIT' &&
                    <input type="hidden" id={`${modalId}-id`}
                           {...register("id", {})}
                           defaultValue={project.id}
                           className="form-control" />
                  }

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-projectName`} className="form-label">
                      {label(labels.projects.fields.NAME)}
                    </label>
                    <input type="text" id={`${modalId}-projectName`}
                           {...register("projectName", { required: true, maxLength: 255, pattern: /^(?! ).*(?<! )$/ })}
                           className={`form-control ${errors.projectName ? 'is-invalid' : ''}`} placeholder={label(labels.projects.fields.NAME)} required />
                    {errors.projectName &&
                        <div className="invalid-feedback">
                          {label(labels.projects.fields.NAME_INVALID)}
                        </div>
                    }
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-description`} className="form-label">
                      {label(labels.projects.fields.DESCRIPTION)}
                    </label>
                    <textarea id={`${modalId}-description`}
                              {...register("description", { required: false, maxLength: 1000, pattern: /^(?! ).*(?<! )$/ })}
                              className={`form-control ${errors.description ? 'is-invalid' : ''}`} placeholder={label(labels.projects.fields.DESCRIPTION)}
                              rows={2}></textarea>
                    {errors.description &&
                        <div className="invalid-feedback">
                          {label(labels.projects.fields.DESCRIPTION_INVALID)}
                        </div>}
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor="sortOrder" className="form-label">
                      {label(labels.projects.fields.SORT_ORDER)}
                    </label>
                    <input type="number" id="sortOrder"
                           {...register("sortOrder", { required: false, max: 1000000 })}
                           className={`form-control ${errors.sortOrder ? 'is-invalid' : ''}`} placeholder={label(labels.projects.fields.SORT_ORDER)} />
                    {errors.sortOrder &&
                        <div className="invalid-feedback">
                          {label(labels.projects.fields.SORT_ORDER_INVALID)}
                        </div>}
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-color`} className="form-label">
                      {label(labels.projects.fields.COLOR)}
                    </label>
                    <select id={`${modalId}-color`} className="form-select"
                            {...register("color")} >
                      {colors.map((color: any, i: number) => (
                          <option key={i}
                                  value={color.code}>
                            {color.label}
                          </option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label htmlFor={`${modalId}-projectTypeId`} className="form-label">
                      {label(labels.projects.fields.TYPE)}
                    </label>
                    <select id={`${modalId}-projectTypeId`} className="form-select"
                            {...register("projectTypeId")} >
                      {projectTypes.map((projectType) => (
                          <option key={projectType.id}
                                  value={projectType.id}>
                            {projectType.projectTypeName}
                          </option>
                      ))}
                    </select>
                  </div>


                </div>
                <div className="modal-footer">
                  <Button classes="btn-default" icon="x" label={label(labels.actions.CANCEL)}
                          onClick={handleCancel} />
                  <Button type="submit" classes="btn-primary" icon="check" label={label(labels.actions.SAVE)} />
                </div>
              </form>
            }
          </ModalFrame>
      </>
  );
}

export default ProjectForm;