import classes from "./MainNav.module.scss";
import {Form, Link, NavLink} from "react-router-dom";
import Icon from "../_general/Icon";
import CONSTANTS from "../../types/constants/constants";
import {AuthState} from "../../types/state/AuthState";
import {useSelector} from "react-redux";
import labels from "../../types/constants/labels";
import useLabels from "../../hooks/use-labels";
import {UserData} from "../../types/user/UserData";
import {hasFeature} from "../../utils/app-utils";
import FEATURES from "../../types/constants/features";

interface Props {
  isLoggedIn: boolean;
  userData: UserData;
  language: string;
  onLogout: () => void;
  onChangeLanguage: () => void;
}

const MainNav = ({ isLoggedIn, userData, language, onLogout, onChangeLanguage}: Props) => {

  const { getLabel: label } = useLabels();

  const appTitle = CONSTANTS.APP_TITLE;

  return (
      <div className={classes.navbarContainerWrapper}>
        <div className="container">
          <nav className={`navbar navbar-expand-lg bg-dark ${classes.navbar}`} data-bs-theme="dark">
            <div className="container-fluid">
              <NavLink to="/home" className="navbar-brand" >{appTitle}</NavLink>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              {isLoggedIn &&
                <div className="collapse navbar-collapse" id="navbarText">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <NavLink to="/home"
                               className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                        {label(labels.nav.HOME)}
                      </NavLink>
                    </li>
                    {hasFeature(FEATURES.EVENTS, userData) &&
                      <li className="nav-item">
                        <NavLink to="/events"
                                 className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                          {label(labels.nav.EVENTS)}
                        </NavLink>
                      </li>
                    }
                    {hasFeature(FEATURES.TIME_RECORDS, userData) &&
                      <li className="nav-item">
                        <NavLink to="/times"
                                 className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                          {label(labels.nav.TIME_RECORDS)}
                        </NavLink>
                      </li>
                    }
                    {hasFeature(FEATURES.PROJECTS, userData) &&
                      <li className="nav-item">
                        <NavLink to="/projects"
                                 className={({ isActive }) => (isActive ? 'active nav-link' : 'nav-link')}>
                          {label(labels.nav.PROJECTS)}
                        </NavLink>
                      </li>
                    }
                  </ul>
                  <ul className="navbar-nav mb-2 mb-lg-0" data-bs-theme="light">
                    <li className="nav-item dropdown">
                      <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                         aria-expanded="false">
                        <Icon icon="person-circle" classes="me-2" />
                      </a>
                      <ul className="dropdown-menu">
                        <li>
                          <Link to="/account" className="dropdown-item" >
                            {label(labels.nav.ACCOUNT)}
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a className="dropdown-item" role="button" onClick={onChangeLanguage}>
                            {label(labels.nav.SWITCH_LANGUAGE, language === 'DE' ? 'EN' : 'DE')}
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li><a className="dropdown-item" role="button" onClick={onLogout}>
                          {label(labels.nav.LOGOUT)}
                        </a></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </nav>
        </div>
      </div>
  );
}
export default MainNav;