import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {createPostRequest} from "../../utils/api-utils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/app-utils";
import {ERROR_CODES} from "../../types/constants/error-codes";
import {useNavigate, useSearchParams} from "react-router-dom";
import {pageActions} from "../../redux/slices/page-slice";
import {authActions} from "../../redux/slices/auth-slice";
import {useDispatch} from "react-redux";
import {Event} from "../../types/event/Event";
import useLabels from "../use-labels";
import labels from "../../types/constants/labels";
import {formatEventValues, parseEventValues} from "../../utils/event-utils";

const useEditEvent = () => {
  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData } = useFetch();

  const [event, setEvent] = useState<Event>(null);

  const [alertData, setAlertData] = useState<AlertData>(null);


  const loadEvent = async (eventId: string) => {
    const resp = await fetchData(`/event/${eventId}`, null, true);
    if (!resp) {
      return;
    }
    if (resp.errorCode && resp.errorCode === ERROR_CODES.LOGIN_INVALID_AUTH_TOKEN) {
      dispatch(authActions.logout());
      navigate('/login?eventType=logged_out');
      return;
    }
    if (resp.event) {
      parseEventValues(resp.event);
      setEvent(resp.event);
      setAlertData(null);
    }
  }

  const saveEditEvent = async (event: Event) : Promise<boolean> => {
    formatEventValues(event);

    const eventData = {
      event: event,
    }
    const reqData = createPostRequest(eventData);
    const respData = await fetchData('/event/update', reqData, true);

    const alert = handleError(respData, label);
    if (alert) {
      setAlertData(alert);
      return false;
    }
    const successAlert = createSuccessAlert(label(labels.events.alerts.EDITED_SUCCESSFULLY, event.eventName));
    dispatch(pageActions.initAlertData(successAlert));

    return true;
    // navigate(`/cards/${event.cardId}`);
  }

  const cancelEditEvent = () => {
    // navigate(`/cards/${card.id}`);
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    event: event,
    alertData: alertData,
    loadEvent: loadEvent,
    saveEditEvent: saveEditEvent,
    cancelEditEvent: cancelEditEvent,
  };

}

export default useEditEvent;