import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {createPostRequest} from "../../utils/api-utils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/app-utils";
import {ERROR_CODES} from "../../types/constants/error-codes";
import {useNavigate, useSearchParams} from "react-router-dom";
import {pageActions} from "../../redux/slices/page-slice";
import {authActions} from "../../redux/slices/auth-slice";
import {useDispatch} from "react-redux";
import {Project} from "../../types/project/Project";
import useLabels from "../use-labels";
import labels from "../../types/constants/labels";

const useEditProject = () => {
  const { getLabel: label } = useLabels();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData } = useFetch();

  const [project, setProject] = useState<Project>(null);

  const [alertData, setAlertData] = useState<AlertData>(null);


  const loadProject = async (projectId: string) => {
    const resp = await fetchData(`/project/${projectId}`, null, true);
    if (!resp) {
      return;
    }
    if (resp.errorCode && resp.errorCode === ERROR_CODES.LOGIN_INVALID_AUTH_TOKEN) {
      dispatch(authActions.logout());
      navigate('/login?eventType=logged_out');
      return;
    }
    if (resp.project) {
      setProject(resp.project);
    }
  }

  const saveEditProject = async (project: Project) => {
    const projectData = {
      project: project,
    }
    const reqData = createPostRequest(projectData);
    const respData = await fetchData('/project/update', reqData, true);

    const alert = handleError(respData, label);
    if (alert) {
      setAlertData(alert);
      return;
    }
    const successAlert = createSuccessAlert(label(labels.projects.alerts.EDITED_SUCCESSFULLY, project.projectName));
    dispatch(pageActions.initAlertData(successAlert));

    // navigate(`/cards/${project.cardId}`);
  }

  const cancelEditProject = () => {
    // navigate(`/cards/${card.id}`);
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    project: project,
    alertData: alertData,
    loadProject: loadProject,
    saveEditProject: saveEditProject,
    cancelEditProject: cancelEditProject,
  };

}

export default useEditProject;