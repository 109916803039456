import classes from "./AccountPage.module.scss";
import personIcon from "bootstrap-icons/icons/person-circle.svg";
import {AuthState} from "../../types/state/AuthState";
import {useSelector} from "react-redux";

const AccountPage = () => {

  const { isLoggedIn, userData }: AuthState = useSelector((state: any) => state.auth);

  if (!isLoggedIn) {
    return;
  }

  if (!userData) {
    return (
        <p>Keinen Account gefunden.</p>
    );
  }

  return (
      <>
        <div className="row">
          <div className="col-1">
            <img src={personIcon} className={`mt-2 me-3 ${classes.personIcon}`} />
          </div>
          <div className="col-11">
            <h3>{userData.firstName} {userData.lastName}</h3>
            <p>{userData.username}</p>
          </div>
        </div>
      </>
  );
};

export default AccountPage;