import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {createPostRequest} from "../../utils/api-utils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/app-utils";
import {useNavigate, useSearchParams} from "react-router-dom";
import {pageActions} from "../../redux/slices/page-slice";
import {authActions} from "../../redux/slices/auth-slice";
import {useDispatch} from "react-redux";
import {TimeRecord} from "../../types/time-record/TimeRecord";
import useLabels from "../use-labels";
import labels from "../../types/constants/labels";
import {createNewTimeRecord, formatTimeRecordValues, parseTimeRecordValues} from "../../utils/time-record-utils";

const useNewTimeRecord = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, hasError, fetchData } = useFetch();

  const [timeRecord, setTimeRecord] = useState<TimeRecord>(null);

  const [alertData, setAlertData] = useState<AlertData>(null);

  const resetTimeRecord = () => {
    const newTimeRecord: TimeRecord = createNewTimeRecord();
    parseTimeRecordValues(newTimeRecord);
    setTimeRecord(newTimeRecord);
  }

  const saveNewTimeRecord = async (timeRecord: TimeRecord) => {
    formatTimeRecordValues(timeRecord);

    const timeRecordData = {
      timeRecord: timeRecord,
    }
    const reqData = createPostRequest(timeRecordData);
    const respData = await fetchData('/time-record/create', reqData, true);

    const alert = handleError(respData, label);
    if (alert) {
      setAlertData(alert);
      return;
    }
    const successAlert = createSuccessAlert(label(labels.timeRecords.alerts.ADDED_SUCCESSFULLY, timeRecord.effort));
    dispatch(pageActions.initAlertData(successAlert));

    searchParams.set('showAlert', 'true');
    // navigate(`/cards/${timeRecord.cardId}`);
  }

  const cancelNewTimeRecord = () => {
    // navigate(`/cards/${card.id}`);
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    timeRecord: timeRecord,
    alertData: alertData,
    resetTimeRecord: resetTimeRecord,
    saveNewTimeRecord: saveNewTimeRecord,
    cancelNewTimeRecord: cancelNewTimeRecord,
  };

}

export default useNewTimeRecord;