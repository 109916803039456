
const DATE_FORMATS: any = {
  DATE: 'yyyy-MM-dd',
  DATE_DE: 'dd.MM.yyyy',
  WEEKDAY: 'EEEEEE',
  MONTH_YEAR: 'MMMM yyyy',
  TIME: 'HH:mm',
  FULL_DE: 'dd.MM.yyyy HH:mm',
  FULL_ISO: 'yyyy-MM-dd\'T\'HH:mm:ssXXX',
}

export default DATE_FORMATS;