import {differenceInMinutes, format, parse} from "date-fns";
import DATE_FORMATS from "../types/constants/date-formats";
import {TimeRecord} from "../types/time-record/TimeRecord";

export const createNewTimeRecord = (): TimeRecord => {
  const timeRecord: TimeRecord = {
    id: null,
    effort: null,
    recordDate: null,
    recordDateAsDate: new Date(),
    recordFrom: null,
    recordTo: null,
    // timeRecordTypeId: timeRecordTypes && timeRecordTypes.length > 0 ? timeRecordTypes[0].id : null,
  };
  return timeRecord;
}

export const parseTimeRecordValues = (timeRecord: TimeRecord) => {
  if (!timeRecord) {
    return;
  }

  if (timeRecord.recordDate) {
    const recordDate: Date = new Date(timeRecord.recordDate);
    const recordDateFormatted: string = format(recordDate, DATE_FORMATS.DATE);
    timeRecord.recordDateAsDate = recordDate;
    timeRecord.recordDateFormatted = recordDateFormatted;

  } else if (timeRecord.recordDateAsDate) {
    const recordDateFormatted: string = format(timeRecord.recordDateAsDate, DATE_FORMATS.DATE);
    timeRecord.recordDateFormatted = recordDateFormatted;
  }

  if (timeRecord.recordFrom) {
    const recordFrom: Date = new Date(timeRecord.recordFrom);
    const recordFromFormatted: string = format(recordFrom, DATE_FORMATS.TIME);
    timeRecord.recordFromAsDate = recordFrom;
    timeRecord.recordFromFormatted = recordFromFormatted;
  } else {
    timeRecord.recordToAsDate = null;
    timeRecord.recordToFormatted = null;
  }

  if (timeRecord.recordTo) {
    const recordTo: Date = new Date(timeRecord.recordTo);
    const recordToFormatted: string = format(recordTo, DATE_FORMATS.TIME);
    timeRecord.recordToAsDate = recordTo;
    timeRecord.recordToFormatted = recordToFormatted;
  } else {
    timeRecord.recordFromAsDate = null;
    timeRecord.recordFromFormatted = null;
  }

};

export const formatTimeRecordValues = (timeRecord: TimeRecord) => {
  if (!timeRecord) {
    return;
  }

  if (timeRecord.recordDateFormatted) {
    const recordDateAsDate: Date = parse(timeRecord.recordDateFormatted, DATE_FORMATS.DATE, new Date());
    timeRecord.recordDateAsDate = recordDateAsDate;
    timeRecord.recordDate = format(timeRecord.recordDateAsDate, DATE_FORMATS.FULL_ISO);
    console.log(timeRecord.recordDate);

    if (timeRecord.recordFromFormatted) {
      const recordFromAsDate: Date = parse(timeRecord.recordFromFormatted, DATE_FORMATS.TIME, recordDateAsDate);
      timeRecord.recordFromAsDate = recordFromAsDate;
      timeRecord.recordFrom = format(recordFromAsDate, DATE_FORMATS.FULL_ISO);
      console.log(timeRecord.recordFrom);
    }

    if (timeRecord.recordToFormatted) {
      const recordToAsDate: Date = parse(timeRecord.recordToFormatted, DATE_FORMATS.TIME, recordDateAsDate);
      timeRecord.recordToAsDate = recordToAsDate;
      timeRecord.recordTo = format(recordToAsDate, DATE_FORMATS.FULL_ISO);
      console.log(timeRecord.recordTo);
    }
  }
}

export const convertToTimeRecord = (formTimeRecord: TimeRecord): TimeRecord => {

  const {
    id,
    effort,
    recordDate,
    recordFrom,
    recordTo,
    projectId,
  } = formTimeRecord;

  const timeRecord: TimeRecord = {
    id: id ? Number(id) : null,
    recordDate: recordDate || null,
    recordFrom: recordFrom || null,
    recordTo: recordTo || null,
    effort: effort || null,
    projectId: Number(projectId),
  }
  return timeRecord;
}

export const getDurationOfTimeRecord = (timeRecord: TimeRecord): number[] => {
  const totalDurationInMinutes: number = differenceInMinutes(timeRecord.recordToAsDate, timeRecord.recordFromAsDate);
  const durationHours: number = Math.trunc(totalDurationInMinutes / 60);
  const durationMinutes: number = totalDurationInMinutes % 60;
  return [durationHours, durationMinutes];
}