import classes from "./Footer.module.scss";

const Footer = () => {
  return (
      <footer className="mt-4">
        <div className={`container ${classes.footerContainer}`}>
          <p className="text-muted mb-0">
            <small>by Peter</small>
          </p>
        </div>
      </footer>
  );
}
export default Footer;