import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {EventsState} from "../../types/state/EventsState";
import {EventType} from "../../types/event/EventType";

const initialState: EventsState = {
  eventTypes: [],
}

const initEventTypes = (eventsState: EventsState, action: PayloadAction<EventType[]>) => {
  eventsState.eventTypes = action.payload;
}


const clear = (eventsState: EventsState) => {
  eventsState.eventTypes = [];
}

const eventsSlice = createSlice({
  name: 'events',
  initialState: initialState,
  reducers: {
    initEventTypes: initEventTypes,
    clear: clear,
  },
});

export const eventsReducer = eventsSlice.reducer;
export const eventsActions = eventsSlice.actions;