import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {createPostRequest} from "../../utils/api-utils";
import {AlertData} from "../../types/components/AlertData";
import {createSuccessAlert, handleError} from "../../utils/app-utils";
import {useNavigate, useSearchParams} from "react-router-dom";
import {pageActions} from "../../redux/slices/page-slice";
import {authActions} from "../../redux/slices/auth-slice";
import {useDispatch} from "react-redux";
import {Event} from "../../types/event/Event";
import useLabels from "../use-labels";
import labels from "../../types/constants/labels";
import {createNewEvent, formatEventValues, parseEventValues} from "../../utils/event-utils";
import {EventType} from "../../types/event/EventType";
import {Color} from "../../types/_general/Color";
import {Project} from "../../types/project/Project";

const useNewEvent = () => {
  const { getLabel: label } = useLabels();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData } = useFetch();

  const [event, setEvent] = useState<Event>(null);

  const [alertData, setAlertData] = useState<AlertData>(null);

  const resetEvent = (colors: Color[], eventTypes: EventType[], projectId: number, eventStart?: Date) => {
    const newEvent: Event = createNewEvent(colors, eventTypes, projectId, eventStart);
    parseEventValues(newEvent);
    setEvent(newEvent);
    setAlertData(null);
  }

  const saveNewEvent = async (event: Event): Promise<boolean> => {
    formatEventValues(event);

    const eventData = {
      event: event,
    }
    const reqData = createPostRequest(eventData);
    const respData = await fetchData('/event/create', reqData, true);

    const alert = handleError(respData, label);
    if (alert) {
      setAlertData(alert);
      return false;
    }
    const successAlert = createSuccessAlert(label(labels.events.alerts.ADDED_SUCCESSFULLY, event.eventName));
    dispatch(pageActions.initAlertData(successAlert));

    return true;
    // navigate(`/cards/${event.cardId}`);
  }

  const cancelNewEvent = () => {
    // navigate(`/cards/${card.id}`);
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    event: event,
    alertData: alertData,
    resetEvent: resetEvent,
    saveNewEvent: saveNewEvent,
    cancelNewEvent: cancelNewEvent,
  };

}

export default useNewEvent;