import {useContext, useState} from "react";
import useFetch from "../use-fetch";
import {ERROR_CODES} from "../../types/constants/error-codes";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {authActions} from "../../redux/slices/auth-slice";
import {Event} from "../../types/event/Event";
import {getCalendarDays, getEventsByDateMap, parseEventValues} from "../../utils/event-utils";
import {Project} from "../../types/project/Project";
import {addDays, format} from "date-fns";
import DATE_DATE_FORMATS from "../../types/constants/date-formats";
import {CalendarDay} from "../../types/calendar/CalendarDay";
import {start} from "repl";
import {AlertData} from "../../types/components/AlertData";
import {createWarningAlert, handleError} from "../../utils/app-utils";
import labels from "../../types/constants/labels";
import useLabels from "../use-labels";

const useEventList = () => {

  const { getLabel: label } = useLabels();


  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, hasError, fetchData } = useFetch();
  const [alertData, setAlertData] = useState<AlertData>(null);

  const [startDate, setStartDate] = useState<Date>(null);
  const [endDate, setEndDate] = useState<Date>(null);

  const [projectId, setProjectId] = useState<number>(null);

  const [events, setEvents] = useState<Event[]>([]);
  const [calendarDays, setCalendarDays] = useState<CalendarDay[]>([]);

  const [eventStart, setEventStart] = useState<Date>(null);
  const [selectedEventId, setSelectedEventId] = useState<number>(null);
  const [selectedEvent, setSelectedEvent] = useState<Event>(null);

  const [showNewModal, setShowNewModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const initDates = () => {
    const start = new Date();
    const end = addDays(start, 7 * 12); // 12 weeks
    setStartDate(start);
    setEndDate(end);
  }

  const initStartDate = (start: Date) => {
    setStartDate(start);
  }

  const initEndDate = (end: Date) => {
    setEndDate(end);
  }

  const initShowNewModal = (show: boolean) => {
    setShowNewModal(show);
  }

  const initShowEditModal = (show: boolean) => {
    setShowEditModal(show);
  }

  const initShowDeleteModal = (show: boolean) => {
    setShowDeleteModal(show);
  }

  const initProjectId = (projects: Project[], projectIdParam: string) => {
    if (projectIdParam) {
      setProjectId(Number(projectIdParam));
    } else {
      navigate(`/events/${projects[0].id}`);
    }
  }

  const loadEvents = async () => {
    const startFormatted = format(startDate, DATE_DATE_FORMATS.DATE);
    const endFormatted = format(endDate, DATE_DATE_FORMATS.DATE);

    let path = `/event/list`;
    path += `?projectId=${projectId}`;
    path += `&start=${startFormatted}`;
    path += `&end=${endFormatted}`;

    const resp = await fetchData(path, null, true);
    if (!resp) {
      return;
    }
    if (resp.errorCode && resp.errorCode === ERROR_CODES.LOGIN_INVALID_AUTH_TOKEN) {
      dispatch(authActions.logout());
      navigate('/login?eventType=logged_out');
      return;
    }
    const alert: AlertData = handleError(resp, label);
    if (alert) {
      setAlertData(alert);
    }
    if (resp.events) {
      resp.events.forEach((event: Event) => {
        parseEventValues(event);
      });
      setEvents(resp.events);
    }
  }

  const initCalendarDays = () => {
    const eventsByDateMap: Map<string, Event[]> = getEventsByDateMap(events);
    const calDays: CalendarDay[] = getCalendarDays(eventsByDateMap, startDate, endDate);
    setCalendarDays(calDays);
    // console.log(calDays);
  }

  const initNewEventWithStart = (eventStart: Date) => {
    setEventStart(eventStart);
  }

  const initEditEvent = (event: Event) => {
    setSelectedEventId(event.id);
  }

  const initDeleteEvent = (event: Event) => {
    setSelectedEvent(event);
  }

  return {
    isLoading: isLoading,
    hasError: hasError,
    alertData: alertData,
    startDate: startDate,
    endDate: endDate,
    projectId: projectId,
    events: events,
    calendarDays: calendarDays,
    eventStart: eventStart,
    selectedEventId: selectedEventId,
    selectedEvent: selectedEvent,
    showNewModal: showNewModal,
    showEditModal: showEditModal,
    showDeleteModal: showDeleteModal,
    initDates: initDates,
    initStartDate: initStartDate,
    initEndDate: initEndDate,
    initShowNewModal: initShowNewModal,
    initShowEditModal: initShowEditModal,
    initShowDeleteModal: initShowDeleteModal,
    initProjectId: initProjectId,
    loadEvents: loadEvents,
    initCalendarDays: initCalendarDays,
    initNewEventWithStart: initNewEventWithStart,
    initEditEvent: initEditEvent,
    initDeleteEvent: initDeleteEvent,
  };

}

export default useEventList;