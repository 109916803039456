import EventsCalendarRow from "./EventsCalendarRow";
import Spinner from "../../_general/Spinner";
import {Event} from "../../../types/event/Event";
import React from "react";
import useLabels from "../../../hooks/use-labels";
import labels from "../../../types/constants/labels";
import {CalendarDay} from "../../../types/calendar/CalendarDay";
import classes from "./EventsCalendarTable.module.scss";
import {format} from "date-fns";
import DATE_FORMATS from "../../../types/constants/date-formats";
import EventsCalendarHeading from "./EventsCalendarHeading";
import Holidays from "date-holidays";

interface Props {
  calendarDays: CalendarDay[];
  isLoading: boolean;
  onInitShowNewModal: (show: boolean) => void;
  onInitShowEditModal: (show: boolean) => void;
  onInitShowDeleteModal: (show: boolean) => void;
  onInitNewEventWithStart: (eventStart: Date) => void;
  onInitEditEvent: (event: Event) => void;
  onInitDeleteEvent: (event: Event) => void;
}
const EventsCalendarTable = ({ calendarDays, isLoading, onInitShowNewModal, onInitShowEditModal, onInitShowDeleteModal,
                               onInitNewEventWithStart, onInitEditEvent, onInitDeleteEvent }: Props) => {

  const { getLabel: label } = useLabels();

  const holidays = new Holidays()
  holidays.init('AT', '9')

  if (isLoading) {
    return (
        <>
          <Spinner />
          <p className="text-muted">{label(labels.events.titles.EVENTS_LOADED)}</p>
        </>
    );
  }
  if (!calendarDays || calendarDays.length === 0) {
    return (
        <p className="text-muted">{label(labels.events.titles.NO_EVENTS_FOUND)}</p>
    );
  }

  return (
      <>
        <table className={`table table-striped ${classes.eventsCalendarTable}`}>
          <tbody>
            {calendarDays.map((calendarDay: CalendarDay, i: number) => (
                <EventsCalendarRow
                        key={i}
                        calendarDay={calendarDay}
                        index={i}
                        holidays={holidays}
                        onInitShowNewModal={onInitShowNewModal}
                        onInitShowEditModal={onInitShowEditModal}
                        onInitShowDeleteModal={onInitShowDeleteModal}
                        onInitNewEventWithStart={onInitNewEventWithStart}
                        onInitEditEvent={onInitEditEvent}
                        onInitDeleteEvent={onInitDeleteEvent} />

             ))}
          </tbody>

        </table>
      </>
  );

}

export default EventsCalendarTable;