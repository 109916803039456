import React, {useEffect, useState} from "react";
import labels from "../../../types/constants/labels";
import DATE_FORMATS from "../../../types/constants/date-formats";
import {format, isValid, parse} from "date-fns";
import useLabels from "../../../hooks/use-labels";

interface Props {
  startDate: Date;
  endDate: Date;
  initStartDate: (startDate: Date) => void;
  initEndDate: (endDate: Date) => void;
}

const EventFilter = ({ startDate, endDate, initStartDate, initEndDate }: Props) => {

  const { getLabel: label } = useLabels();

  const [startDateFormatted, setStartDateFormatted] = useState<string>('');
  const [endDateFormatted, setEndDateFormatted] = useState<string>('');

  const initEventFilter = () => {
    setStartDateFormatted(format(startDate, DATE_FORMATS.DATE));
    setEndDateFormatted(format(endDate, DATE_FORMATS.DATE));
  }

  useEffect(() => {
    if (startDate && endDate) {
      initEventFilter();
    }
  }, [startDate, endDate]);

  const handleStartDateChange = (event: any) => {
    const newStartDateFormatted = event.target.value;
    setStartDateFormatted(newStartDateFormatted);
    const newStartDate: Date = parse(newStartDateFormatted, DATE_FORMATS.DATE, new Date())
    if (isValid(newStartDate) && newStartDate.getFullYear() >= 2020) {
      initStartDate(newStartDate);
    }
  }

  const handleEndDateChange = (event: any) => {
    const newEndDateFormatted = event.target.value;
    setEndDateFormatted(newEndDateFormatted);
    const newEndDate: Date = parse(newEndDateFormatted, DATE_FORMATS.DATE, new Date())
    if (isValid(newEndDate) && newEndDate.getFullYear() >= 2020) {
      initEndDate(newEndDate);
    }
  }

  return (
      <>
        <div className="row">
          <div className="col-md-3 form-group">
            <label htmlFor={`startDateFormatted`} className="form-label">
              {label(labels.events.fields.EVENT_START)}
            </label>
            <input type="date" id={`startDateFormatted`}
                   value={startDateFormatted} onChange={handleStartDateChange}
                   className={`form-control`}
                   placeholder={label(labels.events.fields.EVENT_START)} required />
          </div>

          <div className="col-md-3 form-group">
            <label htmlFor={`endDateFormatted`} className="form-label">
              {label(labels.events.fields.EVENT_END)}
            </label>
            <input type="date" id={`endDateFormatted`}
                   value={endDateFormatted} onChange={handleEndDateChange}
                   className={`form-control`}
                   placeholder={label(labels.events.fields.EVENT_END)} required />
          </div>
        </div>
      </>
  );
}

export default EventFilter;